<template>

    <section class="info-blocks">

        <div class="container">

            <div class="title" v-html="data.title" v-if="data.title">
            </div>

            <div class="row justify-content-center">

                <div class="col-md-5 mb-5" v-for="(b,i) in data.blocks" :key="i">

                    <div class=" info-block">
                        <div class="text">{{b.text}}</div>
                    </div>

                </div>

            </div>

            <div v-if="data.buttonText" class="text-center">
                <a :href="`${mediaBase}${data.file}`" target="_blank" class="button orange">{{data.buttonText}}</a>
            </div>
        </div>

    </section>

</template>

<script>
    import {mediaBase} from "../../repositories/Repository";

    export default {
        name: "InfoBlocksSecond",
        props: ["data"],
        data() {
            return {
                mediaBase: mediaBase
            }
        }
    }
</script>

<style lang="scss" scoped>
    .info-blocks {
        padding: 50px 0;

        .title {
            font-size: 40px;
            line-height: 50px;
            color: #00A0E9;
            text-align: center;
            margin-bottom: 50px;
            @media (max-width: 576px) {
                font-size: 24px;
            }
        }

        .info-block {

            background: url("../../assets/img/grey-bg.png");
            background-size: 100% 100%;
            padding: 50px;
            @media (max-width: 576px) {
                padding: 25px;
            }

            .text {
                font-size: 20px;
                color: #929292;

                @media (max-width: 576px) {
                    font-size: 14px;
                }
            }

        }


    }
</style>