import {createStore} from 'vuex'
import app from './app'

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        app
    }
})
