import {createRouter, createWebHistory} from 'vue-router'
import NotFound from "../views/NotFound";
import PageView from "../views/PageView";
import Admin from "../views/admin/Admin";
import Login from "../views/admin/Login";
import Dashboard from "../views/admin/Dashboard";
import {getMe} from "../repositories/AdminRepository";
import AdminPage from "../views/admin/AdminPage";
import Categories from "../views/admin/Categories";
import News from "../views/admin/News";
import AdminNewsPost from "../views/admin/AdminNewsPost";
import NewsPost from "../views/NewsPost";
import Subscribers from "../views/admin/Subscribers";
import Navigations from "../views/admin/Navigations";
import NavigationItems from "../views/admin/NavigationItems";
import SearchResult from "../views/SearchResult";
import Settings from "../views/admin/Settings";
import Translations from "../views/admin/Translations";
import {getLanguageList, getSettings, getTranslations} from "../repositories/GeneralDataRepository";
import store from "../store"
import PaymentResult from "../views/PaymentResult";
import Payments from "../views/admin/Payments";
import PaymentSubs from "../views/admin/PaymentSubs";
import Contents from "../views/admin/Contents";
import PaymentManagement from "../views/PaymentManagement";
import Users from "../views/admin/Users";

const routes = [

    {
        path: '/notFound',
        name: 'NotFound',
        component: NotFound
    },
    {
        path: '/:language/search', component: SearchResult, name: 'searchResult'
    },
    {
        path: '/:language/paymentManagement/:hash', component: PaymentManagement
    },
    {
        path: '/post/:slug', component: NewsPost, name: 'news'
    },
    {
        path: '/payment/:result', component: PaymentResult, name: 'paymentResult'
    },
    {
        path: '/adminLogin',
        name: 'AdminLogin',
        component: Login
    },
    {
        path: '/admin',
        name: 'Admin',
        component: Admin,
        beforeEnter: (to, from, next) => {
            let token = localStorage.getItem('token');
            if (token !== null) {
                getMe(token).then((data) => {
                    if (data.code === 200) {
                        store.dispatch('app/setAdminUser', data.data);
                        next();
                    } else if (data.code === 401) {
                        localStorage.removeItem("token");
                        next('/adminLogin');
                    } else {
                        alert('Error: ' + data.message);
                    }
                });
            } else {
                next('/adminLogin');
            }

        },
        children: [
            {
                path: '', redirect: "/admin/dashboard"
            },
            {
                path: 'dashboard',
                component: Dashboard
            },
            {
                path: 'page/:id',
                component: AdminPage
            },
            {
                path: 'categories',
                component: Categories
            },
            {
                path: 'settings',
                component: Settings
            },
            {
                path: 'contents',
                component: Contents
            },
            {
                path: 'translations',
                component: Translations
            },
            {
                path: 'news',
                component: News
            },
            {
                path: 'post/:id',
                component: AdminNewsPost
            },
            {
                path: 'subscribers',
                component: Subscribers,
            },
            {
                path: 'payments',
                name: 'payments',
                component: Payments
            },
            {
                path: 'paymentSubs',
                name: 'paymentSubs',
                component: PaymentSubs
            },
            {
                path: 'navigations',
                component: Navigations
            },
            {
                path: 'users',
                component: Users
            },
            {
                path: 'navigation/:id',
                component: NavigationItems
            },
        ]
    },
    {
        path: '/:language/:slug', component: PageView, name: 'pageView',
        alias: '/'
    },
    {
        path: '/:slug', name: 'pageOld'
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {

    let languageCode = localStorage.getItem('frontLanguageCode');
    if (!languageCode) {
        languageCode = 'az';
        localStorage.setItem('frontLanguageCode', languageCode);
    }

    if (to.name === "pageOld") {
        next({name: 'pageView', params: {language: languageCode, slug: to.params.slug}});
    } else {

        getLanguageList().then(async d => {
            store.dispatch('app/setLanguages', d);

            d.forEach(l => {
                if (l.code === languageCode) {
                    store.dispatch('app/setFrontLanguage', l);
                }
            })

            let settings = await getSettings(languageCode);
            await store.dispatch('app/setSettings', settings);
            window.document.title = settings.site_title;

            let translations = await getTranslations(languageCode);
            await store.dispatch('app/setTranslations', translations);


            next();
        })
    }
});

export default router
