<template>


    <div class="container mt-5">

        <div class="d-flex justify-content-between">

            <h2>Subscribers</h2>

            <button class="btn btn-success" @click.prevent="exportList">
                Excel export
            </button>

        </div>



        <div class="my-4">

            <table class="table table-bordered">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Time</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="subscribers.length<1">
                    <td colspan="2">
                        <div class="alert alert-danger">
                            no subscribers
                        </div>
                    </td>
                </tr>
                <tr v-for="s in subscribers" :key="s.id">
                    <td>{{s.email}}</td>
                    <td>{{s.logTime.date}}</td>
                </tr>
                </tbody>
            </table>

        </div>

    </div>


</template>

<script>
    // @ is an alias to /src


    import {
        getSubscriberExport,
        getSubscriberList,
    } from "../../repositories/AdminRepository";
    import {mapState} from "vuex";
    import {mediaBase} from "../../repositories/Repository";

    export default {
        name: "Subscribers",
        components: {},
        data() {

            return {
                subscribers: [],
                mediaBase: mediaBase
            }

        },
        computed: {
            ...mapState({
                adminUser: state => state.app.adminUser,
            })
        },
        methods: {
            loadSubscriberList() {

                getSubscriberList().then((d) => {
                    this.subscribers = d;
                });
            },
            exportList() {
                getSubscriberExport().then((d) => {
                    if (d.code === 200) {
                        window.open(`${this.mediaBase}/${d.path}`);
                    } else {
                        alert(`Error ${d.code}: ${d.message}`);
                    }
                });
            },
        },
        mounted() {
            if (this.adminUser.role !== 'admin') {
                alert('Not allowed');
                this.$router.push('/admin/dashboard');
            } else {
                this.loadSubscriberList();
            }
        }
    }
</script>

<style scoped>


</style>