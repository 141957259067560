import Repository, {baseUrl} from './Repository';

export async function getPage(slug, languageCode) {
    const endPoint = `page/${languageCode}/${slug}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function getNewsList(page, size) {
    const endPoint = `newsList?page=${page}&size=${size}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function getNews(slug) {
    const endPoint = `news/${slug}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function getSettings(languageCode) {
    const endPoint = `${languageCode}/settings`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                if (response.data.code === 200) {
                    return response.data.settings;
                } else {
                    return {};
                }
            } else {
                return {};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {};
        });
}

export async function getTranslations(languageCode) {
    const endPoint = `${languageCode}/translations`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                if (response.data.code === 200) {
                    return response.data.translations;
                } else {
                    return {};
                }
            } else {
                return {};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {};
        });
}

export async function getCategoryList() {
    const endPoint = `categories`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                if (response.data.code === 200) {
                    return response.data.categories;
                } else {
                    return [];
                }
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function getLanguageList() {
    const endPoint = `languages`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                if (response.data.code === 200) {
                    return response.data.languages;
                } else {
                    return [];
                }
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function getNavigation(keyword, languageCode) {
    const endPoint = `${languageCode}/navigation/${keyword}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                if (response.data.code === 200) {
                    return response.data.items;
                } else {
                    return [];
                }
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function subscribe(email) {
    const endPoint = `subscribe?email=${email}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function getSearchResult(query) {
    const endPoint = `search?query=${query}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data.pages;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}



export async function getPaymentDetails(hash) {
    const endPoint = `getPaymentDetails/${hash}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function unsubscribeUser(hash) {
    const endPoint = `unsubscribe/${hash}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function pay(name, fin, email, phone, type, amount, languageCode) {
    const endPoint = `pay?name=${name}&fin=${fin}&email=${email}&phone=${phone}&type=${type}&amount=${amount}&lang=${languageCode}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}



export async function applyVacancy(cv,motivation, onUploadProgress) {
    let formData = new FormData();

    formData.append("cv", cv);
    formData.append("motivation", motivation);

    return await Repository.post(`${baseUrl}/applyVacancy`, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        },
        onUploadProgress
    }).then(response => {
        return response;
    }).catch(error => {
        console.log(JSON.stringify(error));
        return {code: 2, message: JSON.stringify(error)};
    });
}


export async function getPaymentResult(reference) {
    const endPoint = `payment?reference=${reference}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                if(response.data.code===200){
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return false;
        });
}