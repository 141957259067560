<template>


    <div class="container mt-5">


        <h2>Users</h2>

        <div class="my-3">
            <a class="btn btn-success" @click.prevent="addNewPopupIsOpen=true">Create new </a>
        </div>

        <div class="my-4">

            <table class="table table-bordered">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th>-</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="users.length<1">
                    <td colspan="3">
                        <div class="alert alert-danger">
                            no categories
                        </div>
                    </td>
                </tr>
                <tr v-for="c in users" :key="c.id">
                    <td>{{c.name}}</td>
                    <td>{{c.email}}</td>
                    <td>{{c.role}}</td>
                    <td>
                        <span class="badge bg-success" v-if="c.status">active</span>
                        <span class="badge bg-danger" v-else>inactive</span>
                    </td>
                    <td>
                        <a class="btn btn-sm btn-primary me-2" @click="edituser(c.id)">edit</a>
                        <a class="btn btn-sm btn-danger" @click="deleteCategory(c.id)">delete</a>
                    </td>
                </tr>
                </tbody>
            </table>

        </div>

    </div>


    <Modal v-if="addNewPopupIsOpen" size="sm" title="Add new user" @modalClose="closeAddNewPopup">

        <div>

            <form @submit.prevent="addNewCategory">

                <div class="mb-3">
                    <label class="form-label"> Name</label>
                    <input type="text" class="form-control" v-model="addNewName" required>
                </div>

                <div class="mb-3">
                    <label class="form-label"> Email</label>
                    <input type="email" class="form-control" v-model="addNewEmail" required>
                </div>

                <div class="mb-3">
                    <label class="form-label" >Role</label>
                    <select class="form-control" v-model="addNewRole" required>
                        <option value="admin">admin</option>
                        <option value="content">content</option>
                    </select>
                </div>


                <div class="mb-3">
                    <label class="form-label"> Password</label>
                    <input type="text" class="form-control" v-model="addNewPassword" required>
                </div>

                <button type="submit" class="btn btn-primary text-white">Create</button>

            </form>

        </div>

    </Modal>

    <Modal v-if="category.id" size="sm" title="Edit user" @modalClose="closeEditPopup">

        <div>

            <form @submit.prevent="saveCategory()">

                <div class="mb-3">
                    <label class="form-label"> Name</label>
                    <input type="text" class="form-control" v-model="category.name" required>
                </div>


                <div class="mb-3">
                    <label class="form-label"> Email</label>
                    <input type="email" class="form-control" v-model="category.email" required>
                </div>

                <div class="mb-3">
                    <label class="form-label" >Role</label>
                    <select class="form-control" v-model="category.role" required>
                        <option value="admin">admin</option>
                        <option value="content">content</option>
                    </select>
                </div>


                <div class="mb-3">
                    <label class="form-label"> Password</label>
                    <input type="text" class="form-control" v-model="editNewPassword" >
                </div>

                <div class="mb-3">
                    <label class="form-label" for="pageStatus">Status</label>
                    <select class="form-control" id="pageStatus" v-model="categoryStatus" required>
                        <option value="0">inactive</option>
                        <option value="1">active</option>
                    </select>
                </div>


                <button type="submit" class="btn btn-primary text-white">Save</button>

            </form>

        </div>

    </Modal>

</template>

<script>
    // @ is an alias to /src


    import Modal from "../../components/admin/Modal";

    import {
        createUser, deleteUser, getUser, getUserList, saveUser
    } from "../../repositories/AdminRepository";
    import {mapState} from "vuex";

    export default {
        name: "Users",
        components: {Modal},
        data() {

            return {
                users: [],
                category: {},
                addNewPopupIsOpen: false,
                addNewName: '',
                addNewEmail: '',
                addNewRole: '',
                addNewPassword: '',
                editNewPassword: '',
            }

        },
        computed: {
            ...mapState({
                adminUser: state => state.app.adminUser,
            }),
            categoryStatus: {
                get() {
                    return this.category.status ? 1 : 0
                },

                set(value) {
                    this.category.status = value > 0
                }
            },
        },
        methods: {
            loadCategoryList() {

                getUserList().then((d) => {
                    this.users = d;
                });
            },
            addNewCategory() {

                createUser(this.addNewName, this.addNewEmail, this.addNewRole, this.addNewPassword).then((data) => {
                    if (data.code === 200) {
                        alert('Created');
                        this.addNewPopupIsOpen = false;
                        this.addNewName = '';
                        this.addNewEmail = '';
                        this.addNewRole = '';
                        this.addNewPassword = '';
                        this.loadCategoryList();
                    } else {
                        alert(data.message + ": " + data.error);
                    }
                });

            },
            saveCategory() {

                saveUser(this.category.id, this.category.name,  this.category.email,  this.category.role, this.editNewPassword,this.category.status ? 1 : 0).then((data) => {
                    if (data.code === 200) {
                        alert('Saved');
                        this.category = {};
                        this.editNewPassword = '';
                        this.loadCategoryList();
                    } else {
                        alert(data.message + ": " + data.error);
                    }
                });

            },
            async edituser(id) {
                await getUser(id).then((data) => {
                        if (data.code === 200) {
                            this.category = data.user;
                            this.editNewPassword = '';
                        }
                    }
                )
            },
            deleteCategory(id) {
                if (confirm("Delete?")) {
                    deleteUser(id).then(() => {

                        this.loadCategoryList()

                    })
                }
            },
            closeAddNewPopup() {
                this.addNewPopupIsOpen = false;
            },
            closeEditPopup() {
                this.category = {};
            },
        },
        mounted() {
            if (this.adminUser.role !== 'admin') {
                alert('Not allowed');
                this.$router.push('/admin/dashboard');
            } else {
                this.loadCategoryList();
            }
        }
    }
</script>

<style scoped>


</style>