<template>


    <div class="mb-3">
        <label class="form-label" for="inputTitle">Title</label>
        <input type="text" class="form-control" id="inputTitle" :value="inputData.title"
               @input="$emit('dataChange', {key: 'title', val: $event.target.value})">
    </div>


    <div class="mb-3">
        <label class="form-label">Photo</label>
        <file-upload :file-path="inputData.backgroundPhoto"
                     @changed="photoChanged"/>
    </div>

    <div class="mb-3">
        <label class="form-label" for="inputTitle">Button text</label>
        <input type="text" class="form-control" id="buttonText" :value="inputData.buttonText"
               @input="$emit('dataChange', {key: 'buttonText', val: $event.target.value})">
    </div>


    <div class="mb-3">
        <label class="form-label">File</label>
        <file-upload :file-path="inputData.file"
                     @changed="fileChanged"/>
    </div>

    <div class="mb-3">
        <label class="form-label" for="inputLink">Link</label>
        <input type="text" class="form-control" id="inputLink" :value="inputData.link"
               @input="$emit('dataChange', {key: 'link', val: $event.target.value})">
    </div>


</template>

<script>
    import FileUpload from "../FileUpload";


    export default {
        name: "LawDownloadAdmin",
        components: {FileUpload},
        props: ['inputData'],
        emits: ['dataChange'],
        methods: {
            photoChanged(data) {
                this.$emit('dataChange', {key: 'photo', val: data})
            }, fileChanged(data) {
                this.$emit('dataChange', {key: 'file', val: data})
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>