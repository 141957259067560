<template>

    <div class="mb-3">
        <label class="form-label">Background</label>
        <file-upload :file-path="inputData.background"
                     @changed="backgroundChanged"/>
    </div>


</template>

<script>


    import FileUpload from "../FileUpload";

    export default {
        name: "DonatePageAdmin",
        components: {FileUpload},
        props: ['inputData'],
        emits: ['dataChange'],
        methods: {
            backgroundChanged(data){
                this.$emit('dataChange', {key: 'background', val: data})
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>