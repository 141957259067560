<template>
    <div class="staff">

        <div class="container">

            <div class="text-center">
                <h2 class="title">{{data.title}}</h2>
                <p class="lead" v-html="data.subtitle"></p>
                <br>
                <p class="lead">
                    {{data.listTitle}}
                </p>
            </div>

            <div class="row">
                <div class="col-md-6 person" v-for="(p,i) in data.persons" :key="i">
                    <span class="name">{{p.name}}</span> <br>
                    <span class="position">{{p.position}}</span>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
    import {mediaBase} from "../../repositories/Repository";

    export default {
        name: "StaffList",
        props: ['data'],
        data() {
            return {
                mediaBase: mediaBase
            }
        }
    }
</script>

<style lang="scss" scoped>


    .staff {
        background: url("../../assets/img/logo-bg-tile.png") #F0F0F0;
        padding: 50px 0;
        color: #828282;

        .title {
            font-size: 40px;
            color: #00A0E9;
            font-weight: bold;


            @media (max-width: 576px) {
                font-size: 24px;
            }
        }

        .lead {
            font-size: 24px;
            font-weight: bold;


            @media (max-width: 576px) {
                font-size: 14px;
            }

            /deep/ span {
                color: #DE7A2F;
            }
        }

        p {
            font-size: 14px;
            font-weight: normal;
        }

        .person {
            font-size: 14px;


            @media (max-width: 576px) {
                text-align: center;
            }

            .name {
                color: #00A0E9;
                font-weight: bold;
                line-height: 1.25px;
            }

        }
    }


</style>