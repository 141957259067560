<template>


    <div class="container mt-5">


        <h2>Contents</h2>

        <!-- <div class="my-3">
             <a class="btn btn-success" @click.prevent="addNewPopupIsOpen=true">Create new </a>
         </div> -->
        <div class="my-4">

            <table class="table table-bordered">
                <thead>
                <tr>
                    <th>Keyword</th>
                    <th>Title</th>
                    <th>-</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="contents.length<1">
                    <td colspan="3">
                        <div class="alert alert-danger">
                            no content
                        </div>
                    </td>
                </tr>
                <tr v-for="c in contents" :key="c.id">
                    <td>{{c.keyword}}</td>
                    <td>{{c.title}}</td>
                    <td>
                        <a class="btn btn-sm btn-primary me-2" @click="editSetting(c.id)">edit</a>
                    </td>
                </tr>
                </tbody>
            </table>

        </div>

    </div>


    <Modal v-if="content.id" size="md" title="Edit settings" @modalClose="closeEditPopup">

        <div>

            <form @submit.prevent="saveCategory()">

                <div class="mb-3">
                    <label class="form-label"> Keyword</label>
                    <input type="text" class="form-control" v-model="content.keyword" readonly>
                </div>
                <div class="mb-3">
                    <label class="form-label"> Allowed placeholders</label>

                    <div>
                        <code class=" me-2" v-for="p in content.placeholders.split(',')" :key="p">{{p}}</code>
                    </div>

                </div>

                <div class="mb-3">
                    <label class="form-label"> Title</label>
                    <input type="text" class="form-control" v-model="content.title" required>
                </div>
                <div class="mb-3">
                    <label class="form-label">File</label>
                    <file-upload :file-path="content.file" @changed="fileChanged"/>
                </div>
                <div class="mb-3">
                    <label class="form-label"> Description</label>
                    <QuillEditor theme="snow" :content="content.description" contentType="html"
                                 @update:content="content.description= $event" :options="editorOptions"/>

                </div>


                <button type="submit" class="btn btn-primary text-white">Save</button>

            </form>

        </div>

    </Modal>

</template>

<script>
    // @ is an alias to /src

    import {QuillEditor, Quill} from '@vueup/vue-quill';
    import ImageUploader from 'quill-image-uploader';
    import '@vueup/vue-quill/dist/vue-quill.snow.css';

    Quill.register("modules/imageUploader", ImageUploader);

    import Modal from "../../components/admin/Modal";
    import FileUpload from "../../components/admin/FileUpload";

    import {
        getContent, getContentList, saveContent,
    } from "../../repositories/AdminRepository";
    import {mapState} from "vuex";

    export default {
        name: "Settings",
        components: {Modal, QuillEditor, FileUpload},
        data() {

            return {
                contents: [],
                content: {},
                editorOptions: {
                    modules: {
                        toolbar: [
                            ['bold', 'italic', 'underline', 'strike'],
                            [{header: 1}, {header: 2}],
                            [{list: 'ordered'}, {list: 'bullet'}],
                            [{script: 'sub'}, {script: 'super'}],
                            [{header: [1, 2, 3, 4, 5, 6, false]}],
                            [{color: []}, {background: []}],
                            ['clean'],
                            ['link']
                        ]
                    },
                }
            }

        },
        computed: {
            ...mapState(
                {currentLanguage: state => state.app.currentLanguage}
            )
        },
        watch: {
            currentLanguage() {
                this.loadContentList();
            }
        },
        methods: {
            loadContentList() {

                getContentList(this.currentLanguage.code).then((d) => {
                    this.contents = d;
                });
            },
            saveCategory() {

                saveContent(this.content.id, this.content.title, this.content.description, this.content.file).then((data) => {
                    if (data.code === 200) {
                        alert('Saved');
                        this.content = {};
                        this.loadContentList();
                    } else {
                        alert(data.message + ": " + data.error);
                    }
                });

            },
            async editSetting(id) {
                await getContent(id).then((data) => {
                        if (data.code === 200) {
                            this.content = data.content;
                        }
                    }
                )
            },
            closeEditPopup() {
                this.content = {};
            },
            fileChanged(data) {
                this.content.file = data;
            }
        },
        mounted() {
            this.loadContentList();
        }
    }
</script>

<style scoped>


</style>