<template>


    <div class="container mt-5">

        <div class="row">
            <div class="col-6">
                <h2>Payments</h2>

                <a class="text-dark fw-bold">Transactions</a> |
                <router-link :to="{name:'paymentSubs'}">Subscriptions</router-link>
            </div>
            <div class="col-4 text-end">

                <form @submit.prevent="submitSearch">
                    <div class="input-group">
                        <input type="search" class="form-control" v-model="keyword">
                        <button type="submit" class="btn btn-outline-dark">
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                </form>
            </div>
            <div class="col-2 text-end">

                <button class="btn btn-success" @click.prevent="exportList">
                    Excel export
                </button>
            </div>
        </div>


        <div class="my-4">

            <table class="table table-bordered">
                <thead>
                <tr>
                    <th>User</th>
                    <th>Email</th>
                    <th>Amount</th>
                    <th>Type</th>
                    <th>Time</th>
                    <th>Status</th>
                    <th colspan="2">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="payments.length<1">
                    <td colspan="8">
                        <div class="alert alert-danger">
                            Loading payments...
                        </div>
                    </td>
                </tr>
                <tr v-for="p in payments" :key="p.id">
                    <td>{{p.name}} {{p.surname}} <br><strong>{{p.fin}}</strong></td>
                    <td>{{p.email}}<br>{{p.phone}}</td>
                    <td>{{p.amount}} AZN</td>
                    <td>{{p.reason}}</td>
                    <td>{{p.transactionTime.date.split('.')[0]}}</td>
                    <td>
                        <span class="badge bg-secondary" v-if="p.status===0">new</span>
                        <span class="badge bg-success" v-else-if="p.status=== 1">success</span>
                        <span class="badge bg-danger" v-else-if="p.status=== 2">failed</span>
                        <span class="badge bg-primary" v-else-if="p.status=== 3">blocked</span>
                        <span class="badge bg-warning" v-else-if="p.status=== 4">refunded</span>
                        <span class="badge bg-warning" v-else>status_{{p.status}}</span>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-outline-dark me-2" @click.prevent="paymentInfo(p.extraData)"
                                title="payment details">
                            <i class="fa fa-archive"></i>
                        </button>
                        <a class="btn btn-sm btn-outline-dark" target="_blank" :href="`${mediaBase}/${p.agreement}`"
                           v-if="p.agreement"
                           title="agreement">
                            <i class="fa fa-file-text-o"></i>
                        </a>
                    </td>
                    <td>
                        <div v-if="p.status===0">
                            <button class="btn btn-sm btn-danger" @click.prevent="deletePayment(p.id)">
                                delete
                            </button>
                        </div>
                        <div v-if="p.status===1">
                            <button class="btn btn-sm btn-warning" @click.prevent="refundPayment(p.id)">
                                refund
                            </button>
                        </div>
                        <div v-if="p.status===3">
                            <button class="btn btn-sm btn-warning me-2" @click.prevent="refundPayment(p.id)">
                                refund
                            </button>
                            <button class="btn btn-sm btn-warning" @click.prevent="cashInPayment(p.id)">
                                cash in
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>

            <nav aria-label="Page navigation example">
                <ul class="pagination">
                    <li class="page-item">
                        <a class="page-link" @click="page--" v-if="page>1">Previous</a>
                    </li>
                    <li class="page-item active"><a class="page-link" href="#">{{page}}</a></li>
                    <li class="page-item">
                        <a class="page-link" @click="page++" v-if="payments.length===pageSize">Next</a>
                    </li>
                </ul>
            </nav>

        </div>

    </div>

    <Modal v-if="paymentDetails" size="md" title="Payment details"
           @modalClose="closePaymentDetailsPopup">

        <div style="max-height: 70vh; overflow: auto">
            <pre>{{paymentDetails}}</pre>
        </div>

    </Modal>


</template>

<script>
    // @ is an alias to /src

    import Modal from "../../components/Modal";

    import {
        cashInPayment,
        deletePayment, getPaymentExport,
        getPaymentList,   refundPayment,
    } from "../../repositories/AdminRepository";
    import {mediaBase} from "../../repositories/Repository";
    import {mapState} from "vuex";

    export default {
        name: "Payments",
        components: {Modal},
        data() {

            return {
                mediaBase: mediaBase,
                keyword:'',
                page: 1,
                pageSize: 1,
                payments: [],
                paymentDetails: null
            }
        },
        watch:{
          page(){
              this.loadPaymentList();
          }
        },
        computed: {
            ...mapState({
                adminUser: state => state.app.adminUser,
            })
        },
        methods: {
            submitSearch() {

                this.page = 1;

                this.loadPaymentList();
            },
            loadPaymentList() {

                getPaymentList(this.keyword, this.page).then((d) => {
                    this.payments = d.payments;
                    this.pageSize = d.pageSize;
                });
            },

            exportList() {
                getPaymentExport().then((d) => {
                    if (d.code === 200) {
                        window.open(`${this.mediaBase}/${d.path}`);
                    } else {
                        alert(`Error ${d.code}: ${d.message}`);
                    }
                });
            },
            deletePayment(id) {
                if (confirm("Delete?")) {
                    deletePayment(id).then((d) => {
                        if (d.code === 200) {
                            alert('Deleted');
                        } else {
                            alert('Error ' + d.code + ": " + d.message);
                        }
                        this.loadPaymentList();
                    })
                }
            },

            refundPayment(id) {
                if (confirm("Refund?")) {
                    refundPayment(id).then((d) => {
                        if (d.code === 200) {
                            alert('Refunded');
                        } else {
                            alert('Error ' + d.code + ": " + d.message + "; \n" + d.error);
                        }
                        this.loadPaymentList();
                    })
                }
            },
            cashInPayment(id) {
                if (confirm("Move payment from block to your account?")) {
                    cashInPayment(id).then((d) => {
                        if (d.code === 200) {
                            alert('Payment moved to account');
                        } else {
                            alert('Error ' + d.code + ": " + d.message + "; \n" + d.error);
                        }
                        this.loadPaymentList();
                    })
                }
            },
            paymentInfo(data) {
                this.paymentDetails = data;
            },
            closePaymentDetailsPopup() {
                this.paymentDetails = null;
            }

        },
        mounted() {
            if (this.adminUser.role !== 'admin') {
                alert('Not allowed');
                this.$router.push('/admin/dashboard');
            } else {
                this.loadPaymentList();
            }
        }
    }
</script>

<style scoped>


</style>