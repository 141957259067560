<template>
    <section class="hero" :style="{'background-image': `url(${backgroundPath})`}">

        <div class="container">
            <div class="w-75 mx-auto">
                <h1 class="page-title" v-html="data.title"></h1>
            </div>
        </div>
    </section>
</template>

<script>
    import {mediaBase} from "../../repositories/Repository";

    export default {
        name: "PageHeader",
        props: ['data'],
        data() {
            return {
                mediaBase: mediaBase
            }
        },
        computed: {
            backgroundPath() {
                if (this.data.type === "local") {
                    return `${this.data.background}`;
                } else {
                    return `${this.mediaBase}${this.data.background}`;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    .hero {
        background-size: cover;
        background-position: center;
        color: #ffffff;
        padding: 200px 0 100px 0;
        position: relative;

        @media (max-width: 576px) {
            padding: 120px 0 50px 0;
        }

        &:before {
            content: "";
            display: block;
            background: rgba(0, 0, 0, 0.5);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 2;
        }

        .container {
            position: relative;
            z-index: 900;

            .page-title {
                font-size: 60px;
                font-weight: bold;
                margin-bottom: 20px;
                text-align: center;

                @media (max-width: 576px) {
                    font-size: 38px;
                }
            }

        }
    }

</style>