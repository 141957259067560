import {createApp} from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import bootstrap from 'bootstrap';

import {library} from "@fortawesome/fontawesome-svg-core";
import {faFileAlt, faPlusCircle, faArrowDown, faArrowUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import VueGtag from "vue-gtag";

library.add(faFileAlt);
library.add(faPlusCircle);
library.add(faArrowDown);
library.add(faArrowUp);

createApp(App)
    .use(store)
    .use(router)
    .use(bootstrap)
    .use(VueGtag, {
        config: {
            id: "GTM-TGGSR4G"
        }
    }, router)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount('#app')

