<template>


    <div class="container mt-5">

        <div class="row">
            <div class="col-6">
                <h2>Payments</h2>

                <router-link :to="{name:'payments'}">Trasnactions</router-link> |   <a class="text-dark fw-bold">Subscriptions</a>
            </div>
            <div class="col-4 text-end">

                <form @submit.prevent="submitSearch">
                    <div class="input-group">
                        <input type="search" class="form-control" v-model="keyword">
                        <button type="submit" class="btn btn-outline-dark">
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                </form>
            </div>
            <div class="col-2 text-end">

                <button class="btn btn-success" @click.prevent="exportList">
                    Excel export
                </button>
            </div>
        </div>


        <div class="my-4">

            <table class="table table-bordered">
                <thead>
                <tr>
                    <th>User</th>
                    <th>Amount</th>
                    <th>Total paid</th>
                    <th>Subscribed</th>
                    <th>Last success payment</th>
                    <th>Status</th>
                    <th colspan="2">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="payments.length<1">
                    <td colspan="8">
                        <div class="alert alert-danger">
                            Loading payments...
                        </div>
                    </td>
                </tr>
                <tr v-for="p in payments" :key="p.id">
                    <td>{{p.name}} {{p.surname}} (<strong>{{p.fin}}</strong>) <br>{{p.email}}<br>{{p.phone}}</td>
                    <td>{{p.amount}} AZN</td>
                    <td>{{p.totalPaid}} AZN</td>
                    <td>{{p.subscriptionTime.date.split('.')[0]}}</td>
                    <td>
                        <span v-if="p.lastPaymentTime">{{p.lastPaymentTime.date.split('.')[0]}}</span>
                        <span v-else>-</span>
                    </td>
                    <td>
                        <span class="badge bg-secondary" v-if="p.status===0">new</span>
                        <span class="badge bg-danger" v-else-if="p.status=== 4">failed</span>
                        <span class="badge bg-warning" v-else-if="p.status=== 3">expired</span>
                        <span class="badge bg-success" v-else-if="p.status=== 1">active</span>
                        <span class="badge bg-warning" v-else-if="p.status=== 2">unsusbcribed</span>
                        <span class="badge bg-warning" v-else>status_{{p.status}}</span>
                    </td>
                    <td>
                        <div v-if="p.status===0">
                            <button class="btn btn-sm btn-danger" @click.prevent="deleteSubscription(p.id)">
                                delete
                            </button>
                        </div>
                        <div v-if="p.status===1">
                            <button class="btn btn-sm btn-warning" @click.prevent="unsubscribe(p.id)">
                                unsubscribe
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>

            <nav aria-label="Page navigation example">
                <ul class="pagination">
                    <li class="page-item">
                        <a class="page-link" @click="page--" v-if="page>1">Previous</a>
                    </li>
                    <li class="page-item active"><a class="page-link" href="#">{{page}}</a></li>
                    <li class="page-item">
                        <a class="page-link" @click="page++" v-if="payments.length===pageSize">Next</a>
                    </li>
                </ul>
            </nav>

        </div>

    </div>


</template>

<script>
    // @ is an alias to /src

    import {
        deleteSubscription,   getSubscribtionExport, getSubscriptionsList, unSubSubscription,
    } from "../../repositories/AdminRepository";
    import {mapState} from "vuex";
    import {mediaBase} from "../../repositories/Repository";

    export default {
        name: "PaymentSubs",
        components: {},
        data() {

            return {
                mediaBase: mediaBase,
                payments: [],
                keyword:'',
                page: 1,
                pageSize: 1,
                paymentDetails: null
            }
        },
        computed: {
            ...mapState({
                adminUser: state => state.app.adminUser,
            })
        },
        watch:{
            page(){
                this.loadPaymentList();
            }
        },
        methods: {
            submitSearch() {

                this.page = 1;

                this.loadPaymentList();
            },
            loadPaymentList() {

                getSubscriptionsList(this.keyword, this.page).then((d) => {
                    this.payments = d.subscriptions;
                    this.pageSize = d.pageSize;
                });
            },

            exportList() {
                getSubscribtionExport().then((d) => {
                    if (d.code === 200) {
                        window.open(`${this.mediaBase}/${d.path}`);
                    } else {
                        alert(`Error ${d.code}: ${d.message}`);
                    }
                });
            },

            deleteSubscription(id) {
                if (confirm("Delete?")) {
                    deleteSubscription(id).then((d) => {
                        if (d.code === 200) {
                            alert('Deleted');
                        } else {
                            alert('Error ' + d.code + ": " + d.message);
                        }
                        this.loadPaymentList();
                    })
                }
            },

            unsubscribe(id) {
                if (confirm("Cancel subscription?")) {
                    unSubSubscription(id).then((d) => {
                        if (d.code === 200) {
                            alert('Canceled');
                        } else {
                            alert('Error ' + d.code + ": " + d.message + "; \n" + d.error);
                        }
                        this.loadPaymentList();
                    })
                }
            },

        },
        mounted() {
            if (this.adminUser.role !== 'admin') {
                alert('Not allowed');
                this.$router.push('/admin/dashboard');
            } else {
                this.loadPaymentList();
            }
        }
    }
</script>

<style scoped>


</style>