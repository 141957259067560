<template>


    <div class="container mt-5">


        <h2>News</h2>

        <div class="my-3">
            <a class="btn btn-success" @click.prevent="addNewPopupIsOpen=true">Create new </a>
        </div>

        <div class="my-4">

            <table class="table table-bordered">
                <thead>
                <tr>
                    <th>Title</th>
                    <th>Category</th>
                    <th>Status</th>
                    <th>-</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="newsList.length<1">
                    <td colspan="5">
                        <div class="alert alert-danger">
                            no news post found
                        </div>
                    </td>
                </tr>
                <tr v-for="n in newsList" :key="n.id">
                    <td>{{n.title}}</td>
                    <td>{{n.category.name}}</td>
                    <td>
                        <span class="badge bg-success" v-if="n.status">active</span>
                        <span class="badge bg-danger" v-else>inactive</span>
                    </td>
                    <td>
                        <router-link :to="`/admin/post/${n.id}`" class="btn btn-sm btn-primary me-2">edit</router-link>
                        <a class="btn btn-sm btn-danger" @click="deleteNews(n.id)">delete</a>
                    </td>
                </tr>
                </tbody>
            </table>

        </div>

    </div>


    <Modal v-if="addNewPopupIsOpen" size="md" title="Add new post" @modalClose="closeAddNewPopup">

        <div>

            <form @submit.prevent="addNewPost">

                <div class="mb-3">
                    <label class="form-label"> Title</label>
                    <input type="text" class="form-control" v-model="addNewTitle" required>
                </div>

                <div class="mb-3">
                    <label class="form-label"> Category</label>
                    <select type="text" class="form-control" v-model="addNewCategory" required>
                        <option v-for="c in categories" :key="c.id" :value="c.id">{{c.name}}</option>
                    </select>
                </div>

                <button type="submit" class="btn btn-primary text-white">Create</button>

            </form>

        </div>

    </Modal>

</template>

<script>
    // @ is an alias to /src


    import Modal from "../../components/admin/Modal";

    import {
        createNews, deleteNews, getCategoryList,
        getNewsList

    } from "../../repositories/AdminRepository";

    export default {
        name: "News",
        components: {Modal},
        data() {

            return {
                categories: [],
                newsList: [],
                addNewPopupIsOpen: false,
                addNewTitle: '',
                addNewCategory: 0,
            }

        },
        methods: {
            loadNewsList() {

                getNewsList().then((d) => {
                    this.newsList = d;
                });
            },
            addNewPost() {

                createNews(this.addNewTitle,'', this.addNewCategory).then((data) => {
                    if (data.code === 200) {
                        alert('Created');
                        this.$router.push('/admin/post/' + data.id);
                    } else {
                        alert(data.message + ": " + data.error);
                    }
                });

            },
            deleteNews(id) {
                if (confirm("Delete?")) {
                    deleteNews(id).then(() => {

                        this.loadNewsList()

                    })
                }
            },
            closeAddNewPopup() {
                this.addNewPopupIsOpen = false;
            },
        },
        mounted() {
            this.loadNewsList();

            getCategoryList().then(d => {
                this.categories = d;
            })
        }
    }
</script>

<style scoped>


</style>