<template>
    <section class="sponsorship-packages">

        <div class="container">

            <div class="title">{{data.title}}</div>


            <div class="row" v-if="screenSize> 768">

                <div class="col-md" v-for="(pack,id) in data.packages" :key="id">

                    <div class="sponsorship-package" :class="pack.color">

                        <div class="header">
                            <div class="name">{{pack.name}}</div>
                            <div class="mt-2 mb-3"><img src="../../assets/img/azn-white.svg" alt="AZN"></div>
                            <div class="price">{{pack.price}}</div>
                        </div>
                        <div class="body d-flex flex-column justify-content-between">

                            <div>
                                <div v-for="f in pack.features.split('\n')" :key="f">
                                    <i class="fa fa-check-circle" size="sm"/>
                                    {{f}}
                                </div>
                            </div>


                        </div>

                        <div class="mt-4 bottom-button">
                            <a @click="scrollToForm" class="button small orange">
                                {{pack.buttonText}}
                            </a>
                        </div>

                    </div>

                </div>

            </div>

            <div v-else>

                <div class="position-relative px-5">

                    <div class="swiper-button-prev stats-prev"></div>
                    <div class="swiper-button-next stats-next"></div>

                    <swiper :slides-per-view="1" :autoplay="{delay: 5000,}" :space-between="0" :autoHeight="true"
                            :navigation="{ nextEl: '.stats-next', prevEl: '.stats-prev', }">
                        <swiper-slide v-for="(pack,id) in data.packages" :key="id">

                            <div class="sponsorship-package" :class="pack.color">

                                <div class="header">
                                    <div class="name">{{pack.name}}</div>
                                    <div class="mt-2 mb-3"><img src="../../assets/img/azn-white.svg" alt="AZN"></div>
                                    <div class="price">{{pack.price}}</div>
                                </div>
                                <div class="body d-flex flex-column justify-content-between">

                                    <div>
                                        <div v-for="f in pack.features.split('\n')" :key="f">
                                            <i class="fa fa-check-circle" size="sm"/>
                                            {{f}}
                                        </div>
                                    </div>


                                </div>



                            </div>

                        </swiper-slide>

                    </swiper>
                </div>

                <div class="mt-4 text-center">
                    <a @click="scrollToForm" class="button small orange">
                        {{ data.packages[0].buttonText}}
                    </a>
                </div>


            </div>

        </div>

    </section>
</template>

<script>

    import SwiperCore, {A11y, Navigation} from 'swiper';
    import {Swiper, SwiperSlide} from 'swiper/vue';

    SwiperCore.use([A11y, Navigation]);
    // Import Swiper styles
    import "swiper/swiper-bundle.css";


    export default {
        name: "SponsorshipPackages",
        props: ['data'],
        components: {Swiper, SwiperSlide},
        data() {
            return {
                screenSize: 0
            }
        },
        methods: {
            scrollToForm() {
                document.getElementById('sponsorshipForm').scrollIntoView({
                    behavior: "smooth"
                })
            }
        },
        mounted() {
            this.screenSize = window.innerWidth
        }
    }
</script>

<style lang="scss" scoped>

    .sponsorship-packages {
        background: url("../../assets/img/logo-bg-tile.png") #F0F0F0;
        padding: 50px 0;
        color: #828282;

        .title {
            text-align: center;
            font-size: 40px;
            color: #00A0E9;
            margin-bottom: 50px;
            font-weight: bold;

            @media (max-width: 576px) {
                font-size: 24px;
            }
        }

        .sponsorship-package {
            display: block;
            border-radius: 40px;
            background: #ffffff;
            overflow: hidden;
            height: 100%;
            padding-bottom: 70px;
            position: relative;

            @media (max-width: 576px) {
                padding-bottom: 20px;
            }

            &.platinum {
                color: #828388;

                .header {
                    background: linear-gradient(180deg, #E3E4E6 0%, #AEAEAE 54.69%, #E3E4E6 100%);
                }
            }

            &.gold {
                color: #B38A59;

                .header {
                    background: linear-gradient(180deg, #E1D1B0 0%, #B18856 54.69%, #F9E4B9 100%);
                }
            }

            &.silver {
                color: #646A7A;

                .header {
                    background: linear-gradient(180deg, #E0E4F8 0%, #616777 54.69%, #DBE0F3 100%);
                }
            }

            &.bronze {
                color: #856654;

                .header {
                    background: linear-gradient(180deg, #F5E0CE 0%, #836452 54.69%, #F5E0CE 100%);
                }
            }

            .header {
                padding: 50px 20px 0;
                text-align: center;
                position: relative;

                .name {
                    font-size: 40px;
                    line-height: 50px;
                    font-weight: bold;
                    color: white;
                }

                .price {
                    display: inline-block;
                    padding: 5px 20px;
                    font-size: 35px;
                    line-height: 35px;
                    margin: -10px;
                    overflow: hidden;
                    background: url("../../assets/img/pack-price-bg.png") top center no-repeat;
                    background-size: 100% 100%;
                }
            }

            .body {
                padding: 15px;
                text-align: center;
                font-size: 14px;
            }

            .bottom-button {
                position: absolute;
                bottom: 30px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .swiper-button-prev, .swiper-button-next {
            font-size: 15px !important;
            color: #717070;
            &:after{
                font-size: 25px !important;
            }
        }

    }

</style>