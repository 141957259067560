<template>


    <div class="form-floating mb-2">
        <input type="text" class="form-control" id="inputTitle" :value="inputData.title"
               @input="$emit('dataChange', {key: 'title', val: $event.target.value})">
        <label  for="inputTitle">Title</label>
    </div>

    <div class="form-floating">
        <input type="text" class="form-control" id="inputLinkText" :value="inputData.subscribeText"
               @input="$emit('dataChange', {key: 'subscribeText', val: $event.target.value})">
        <label for="inputLinkText">subscribe button text</label>
    </div>


</template>

<script>


    export default {
        name: "LatestNewsAdmin",
        components: {},
        props: ['inputData'],
        emits: ['dataChange'],
        methods: {
        }
    }
</script>

<style lang="scss" scoped>
</style>