<template>

    <div class="bg-primary ">
        <div class="container">
            <div class=" vh-100 d-flex align-items-center justify-content-center ">
                <div>
                    <div class="w-75 mx-auto">
                        <img alt="Logo" src="../../assets/logo.png" class="img-fluid">
                    </div>

                    <div class="card mt-5">
                        <div class="card-body">


                            <form @submit.prevent="loginUser">
                                <div class="form-group">
                                    <label for="login" class="form-label">Login</label>
                                    <div>
                                        <input type="email" id="login" class="form-control" v-model="login">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="password" class="form-label">Password</label>
                                    <div>
                                        <input type="password" id="password" class="form-control" v-model="password">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="mt-3">
                                        <button class="btn btn-success">Login</button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src

    import {loginUser} from "../../repositories/AdminRepository";

    export default {
        name: 'Login',
        components: {},
        data() {
            return {
                login: '',
                password: ''
            }
        },
        methods: {

            loginUser() {

                loginUser(this.login, this.password).then(data => {

                    if (data.length < 1) {
                        alert("Something when wrong");
                    } else {

                        if (data.code === 200) {
                            localStorage.setItem("token", data.token);
                            this.$router.push("/admin/dashboard");
                        } else {

                            alert("Error " + data.code + "! \n" + data.message + ":" + data.error);

                        }

                    }

                })

            }

        }
    }
</script>

<style scoped>


</style>