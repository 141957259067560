<template>


    <div class="container mt-5">

        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/admin">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link to="/admin/news">News</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">{{news.title}}</li>
            </ol>
        </nav>


        <h2>{{news.title}}</h2>
        <p class="text-secondary">
            <code>/{{news.slug}}</code>
        </p>

        <form @submit.prevent="saveNews" v-if="news.id">

            <div class="row">
                <div class="col-md-8">
                    <div class="mb-3">
                        <label class="form-label" for="pageTitle">Title</label>
                        <input type="text" class="form-control" id="pageTitle" v-model="news.title" required>
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="pageSlug">Slug</label>
                        <input type="text" class="form-control" id="pageSlug" v-model="news.slug" required>
                    </div>


                    <div class="mb-3">
                        <label class="form-label" for="pageDate">Date</label>
                        <input type="date" class="form-control" id="pageDate" v-model="newsTime" required>
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="pageCat">Category</label>
                        <select class="form-control" id="pageCat" v-model="news.category.id" required>
                            <option v-for="c in categories" :key="c.id" :value="c.id">{{c.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card mb-3">
                        <div class="card-body">
                            <label class="form-label">Photo</label>
                            <file-upload :file-path="news.photo" @changed="photoChanged"/>
                        </div>
                    </div>
                </div>
            </div>


            <div class="mb-3" v-if="news.id">
                <label class="form-label">Text</label>
                <QuillEditor theme="snow"  :content="news.data" contentType="html"
                             @update:content="news.data= $event" :options="editorOptions"/>
            </div>


            <div class="mb-3">
                <label class="form-label" for="pageStatus">Status</label>
                <select class="form-control" id="pageStatus" v-model="newsStatus" required>
                    <option value="0">inactive</option>
                    <option value="1">active</option>
                </select>
            </div>

            <button type="submit" class="btn btn-primary text-white">Save</button>

        </form>
    </div>

</template>

<script>
    // @ is an alias to /src

    import {QuillEditor, Quill} from '@vueup/vue-quill';
    import ImageUploader from 'quill-image-uploader';
    import '@vueup/vue-quill/dist/vue-quill.snow.css';

    Quill.register("modules/imageUploader", ImageUploader);


    import {
        getCategoryList,
        getNews, saveNews, upload
    } from "../../repositories/AdminRepository";
    import FileUpload from "../../components/admin/FileUpload";


    export default {
        name: "AdminNewsPost",
        components: {
            FileUpload, QuillEditor
        },
        data() {
            return {
                news: {},
                categories: [],
                editorOptions: {
                    modules: {
                        toolbar: [
                            ['bold', 'italic', 'underline', 'strike'],
                            [{header: 1}, {header: 2}],
                            [{list: 'ordered'}, {list: 'bullet'}],
                            [{script: 'sub'}, {script: 'super'}],
                            [{header: [1, 2, 3, 4, 5, 6, false]}],
                            [{color: []}, {background: []}],
                            ['clean'],
                            ['link', 'image', 'video']
                        ]
                        , imageUploader: {
                            upload: (file) => {
                                return new Promise((resolve, reject) => {
                                    upload(file, event => {
                                        console.log(Math.round((100 * event.loaded) / event.total));
                                    }).then(response => {
                                        console.log(response);
                                        if (response.data.code === 200) {
                                            console.log(reject);
                                            setTimeout(() => {
                                                resolve(
                                                    "https://api.soschildren.az/"+response.data.path
                                                );
                                            }, 500);
                                        }
                                    })
                                        .catch((e) => {
                                            console.log(e);
                                        });

                                });


                            },
                        },
                    },
                }
            }
        },
        computed: {
            currentPageId() {
                return this.$route.params.id;
            },
            newsStatus: {
                get() {
                    return this.news.status ? 1 : 0
                },

                set(value) {
                    this.news.status = value > 0
                }
            },
            newsTime: {
                get() {
                    return this.news.logTime.date.split(" ")[0]
                },

                set(value) {
                    return this.news.logTime.date = value;
                }
            }
        },
        methods: {
            loadNews() {
                getNews(this.currentPageId).then((data) => {
                    if (data.code === 200) {
                        this.news = data.news;
                    }
                })
            },
            saveNews() {
                if (this.news.title !== '' && this.news.slug !== '') {
                    saveNews(this.news.id, this.news.title, this.news.logTime.date, this.news.category.id, this.news.slug, this.news.photo, this.news.data, this.news.status ? 1 : 0).then((data) => {
                        if (data.code === 200) {
                            alert("Saved");
                            this.loadNews();
                        } else {
                            alert(data.message);
                        }

                    })
                }
            },
            photoChanged(data) {
                this.news.photo = data;
            }
        },
        mounted() {
            this.loadNews();


            getCategoryList().then(d => {
                this.categories = d;
            })
        },
        watch: {
            '$route'() {
                this.loadNews();
                window.scrollTo(0, 0);
            },
        }
    }
</script>

<style scoped>


</style>