<template>


    <div class="container mt-5">


        <h2>Navigations</h2>

        <div class="my-4">

            <table class="table table-bordered">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>keyword</th>
                    <th>-</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="navigations.length<1">
                    <td colspan="3">
                        <div class="alert alert-danger">
                            no navigation
                        </div>
                    </td>
                </tr>
                <tr v-for="n in navigations" :key="n.id">
                    <td>{{n.name}}</td>
                    <td>{{n.keyword}}</td>
                    <td>
                        <router-link class="btn btn-sm btn-primary me-2" :to="`/admin/navigation/${n.id}`">manage</router-link>
                    </td>
                </tr>
                </tbody>
            </table>

        </div>

    </div>


</template>

<script>
    // @ is an alias to /src


    import {
        getNavigationList
    } from "../../repositories/AdminRepository";

    export default {
        name: "Navigations",
        components: {},
        data() {

            return {
                navigations: [],
            }

        },
        methods: {
            loadNavigationList() {

                getNavigationList().then((d) => {
                    this.navigations = d;
                });
            },
        },
        mounted() {
            this.loadNavigationList();
        }
    }
</script>

<style scoped>


</style>