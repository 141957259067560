<template>
    <div class="hero">
        <div class="container">

            <div class="row">
                <div class="col-md-6 d-flex align-items-center">

                    <div class="hero-content">
                        <h1 class="hero-title">Yüklənilir...</h1>

                    </div>

                </div>
                <div class="col-md-6"></div>
            </div>

        </div>
    </div>

</template>

<script>

    export default {
        name: 'Loading',
        components: {},

        mounted() {


        }
    }
</script>

<style lang="scss" scoped>
    .hero {
        display: none;
        background: url("../assets/img/hero.png");
        background-size: cover;
        color: #ffffff;
        padding: 100px 0;
        position: relative;

        &:before {
            content: "";
            display: block;
            background: rgba(0, 0, 0, 0.5);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 2;
        }

        .container {
            position: relative;
            z-index: 10001;

            .hero-title {
                font-size: 75px;
                font-weight: bold;
                margin-bottom: 20px;
            }

            .hero-link {
                color: #ffffff;
                font-size: 25px;
                text-decoration: none;
            }
        }
    }

</style>
