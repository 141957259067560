<template>


    <div class="container mt-5">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/admin">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link to="/admin/navigations">Navigations</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">{{navigation.name}}</li>
            </ol>
        </nav>


        <h2>{{navigation.name}} <code>&lt;{{navigation.keyword}}&gt;</code></h2>

        <div class="my-4">


            <div v-if="items.length<1">
                <div class="alert alert-danger">
                    no menu items
                </div>
            </div>
            <div>
                <div class="card mb-3" v-for="i in topItems" :key="i.id">
                    <div class="card-body" @click.prevent="editItem(i.id)">
                        <strong>{{i.title}}</strong> <i class="fa fa-pencil-square"></i> <br>
                        link: <code>{{i.link}}</code>
                    </div>
                    <hr class="m-0 p-0">
                    <div class="card-body ps-5">
                        <div class="pb-3" v-for="s in subItems(i.id)" :key="s.id">
                            <div @click.prevent="editItem(s.id)">
                                - {{s.title}}
                                <code>{{s.link}}</code> <i class="fa fa-pencil-square"></i> <br>
                            </div>
                            <div class="ps-5">
                                <div class="pb-1" v-for="ss in subItems(s.id)" :key="ss.id"
                                     @click.prevent="editItem(ss.id)">
                                    <div>- {{ss.title}}</div>
                                    <code>{{s.link}}</code>
                                </div>
                                <a class="text-primary" @click.prevent="addNewPopup(s.id)">
                                    add submenu
                                </a>

                            </div>
                        </div>
                        <a class="btn btn-sm btn-outline-dark" @click.prevent="addNewPopup(i.id)">
                            create new menu item
                        </a>
                    </div>

                </div>
                <div class="card bg-secondary" @click.prevent="addNewPopup(null)">
                    <div class="card-body text-white">
                        Create new top level menu
                    </div>
                </div>
            </div>


        </div>

    </div>


    <Modal v-if="addNewPopupIsOpen" size="md" title="Add new menu item" @modalClose="closeAddNewPopup">

        <div>

            <form @submit.prevent="addNewItem">

                <div class="mb-3">
                    <label class="form-label"> Name</label>
                    <input type="text" class="form-control" v-model="addNewName" required>
                </div>

                <div class="mb-3">
                    <label class="form-label"> List order</label>
                    <input type="number" class="form-control" v-model="addNewListOrder" required>
                </div>

                <button type="submit" class="btn btn-primary text-white">Create</button>

            </form>

        </div>

    </Modal>

    <Modal v-if="item.id" size="md" title="Edit menu item" @modalClose="closeEditPopup">

        <div>

            <form @submit.prevent="saveItem()">

                <div class="mb-3">
                    <label class="form-label"> Title</label>
                    <input type="text" class="form-control" v-model="item.title" required>
                </div>

                <div class="mb-3">
                    <label class="form-label"> List order</label>
                    <input type="number" class="form-control" v-model="item.listOrder" required>
                </div>

                <div class="mb-3">
                    <label class="form-label" for="link">Link</label>
                    <select class="form-control" id="link" v-model="item.link" required>
                        <option value="#">-no where -</option>
                        <option v-for="p in pageList" :key="p.id" :value="`/${p.slug}`">
                            {{pageName(p)}}
                        </option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label" for="pageStatus">Target</label>
                    <select class="form-control" id="pageStatus" v-model="item.target" required>
                        <option value="_self">same page</option>
                        <option value="_blank">new page</option>
                    </select>
                </div>


                <button type="submit" class="btn btn-primary text-white">Save</button>
                <button type="button" class="btn btn-danger text-white ms-3" @click.prevent="deleteItem(item.id)">
                    Delete
                </button>

            </form>

        </div>

    </Modal>

</template>

<script>
    // @ is an alias to /src


    import Modal from "../../components/admin/Modal";

    import {
        createNavigationItem, deleteNavigationItem, getNavigation, getNavigationItem, getPageListFull,
        saveNavigationItem
    } from "../../repositories/AdminRepository";
    import {mapState} from "vuex";

    export default {
        name: "NavigationItems",
        components: {Modal},
        data() {

            return {
                items: [],
                navigation: {},
                pages: [],
                item: {},
                addNewPopupIsOpen: false,
                addNewName: '',
                addNewListOrder: 1,
                addNewParent: null
            }

        },
        computed: {
            ...mapState(
                {currentLanguage: state => state.app.currentLanguage}
            ),
            topItems() {
                return this.items.filter(i => i.parent === null)
            },
            currentNavigationId() {
                return this.$route.params.id;
            },
            pageList(){
                 return this.pages.filter(p=> p.language.code === this.currentLanguage.code)
            },

            categoryStatus: {
                get() {
                    return this.category.status ? 1 : 0
                },

                set(value) {
                    this.category.status = value > 0
                }
            },
        },
        watch: {
            currentLanguage() {
                this.loadNavigation();
            }
        },
        methods: {
            subItems(id) {
                return this.items.filter(i => i.parent !== null && i.parent.id === id)
            },
            pageName(p) {
                console.log(p);
                let t = '';

                if (p.page !== null) {
                    t = `> ${p.page.title}  > ${p.title}`;
                } else {
                    t = `> ${p.title}`;
                }
                return t;
            },
            loadNavigation() {
                getNavigation(this.currentLanguage.code, this.currentNavigationId).then(d => {
                    if (d.code === 200) {
                        this.navigation = d.navigation;
                        this.items = d.items;
                    }
                })
            },
            addNewPopup(parentId) {
                this.addNewPopupIsOpen = true;
                this.addNewParent = parentId;
            },
            addNewItem() {

                createNavigationItem(this.addNewName, this.addNewListOrder, this.navigation.id, this.addNewParent).then((data) => {
                    if (data.code === 200) {
                        alert('Created');
                        this.addNewPopupIsOpen = false;
                        this.addNewName = '';
                        this.addNewListOrder = 1;
                        this.addNewParent = null;
                        this.editItem(data.id);
                        this.loadNavigation();
                    } else {
                        alert(data.message + ": " + data.error);
                    }
                });

            },
            saveItem() {

                saveNavigationItem(this.item.id, this.item.title, this.item.listOrder, this.item.link, this.item.target).then((data) => {
                    if (data.code === 200) {
                        alert('Saved');
                        this.item = {};
                        this.loadNavigation();
                    } else {
                        alert(data.message + ": " + data.error);
                    }
                });

            },
            async editItem(id) {
                await getNavigationItem(id).then((data) => {
                        if (data.code === 200) {
                            this.item = data.item;
                        }
                    }
                )
            },
            deleteItem(id) {
                if (confirm("Delete?")) {
                    deleteNavigationItem(id).then(() => {
                        alert('Deleted');
                        this.item = {};
                        this.loadNavigation();
                    })
                }
            },
            closeAddNewPopup() {
                this.addNewPopupIsOpen = false;
            },
            closeEditPopup() {
                this.item = {};
            },
        },
        mounted() {
            this.loadNavigation()
            getPageListFull(null).then(d => {
                this.pages = d
            })
        }
    }
</script>

<style scoped>


</style>