<template>


    <div class="container mt-5">


        <h2>Website</h2>

        <page-list parent="0"/>


    </div>


</template>

<script>
    // @ is an alias to /src


    import PageList from "../../components/admin/PageList";

    export default {
        name: "AdminDashboard",
        components: {PageList},
        data() {

            return {}

        },
        methods: {},
        mounted() {
        }
    }
</script>

<style scoped>


</style>