export default {
    namespaced: true,
    state: {
        openModalCount: 0,
        settings: {},
        translations: {},
        currentLanguage: {},
        frontLanguage: {},
        languages: [],
        adminUser: null
    },
    mutations: {
        increaseOpenModalCount(state) {
            state.openModalCount++;
        },
        decreaseOpenModalCount(state) {
            state.openModalCount--;
        },
        setSettings(state, payload) {
            state.settings = payload;
        },
        setTranslations(state, payload) {
            state.translations = payload;
        },
        setCurrentLanguage(state, payload) {
            state.currentLanguage = payload;
        },
        setFrontLanguage(state, payload) {
            state.frontLanguage = payload;
        },
        setLanguages(state, payload) {
            state.languages = payload;
        },
        setAdminUser(state, payload) {
            state.adminUser = payload;
        },
    },
    actions: {
        setSettings(context, payload) {
            context.commit('setSettings', payload)
        },
        setTranslations(context, payload) {
            context.commit('setTranslations', payload)
        },
        setCurrentLanguage(context, payload) {
            context.commit('setCurrentLanguage', payload)
        },
        setFrontLanguage(context, payload) {
            context.commit('setFrontLanguage', payload)
        },
        setLanguages(context, payload) {
            context.commit('setLanguages', payload)
        },
        setAdminUser(context, payload) {
            context.commit('setAdminUser', payload)
        },
    },
    modules: {}
}
