<template>


    <div class="container mt-5">

        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/admin">Dashboard</router-link>
                </li>
                <li v-if="page.page" class="breadcrumb-item">
                    <router-link :to="`/admin/page/${page.page.id}`">{{page.page.title}}</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">{{page.title}}</li>
            </ol>
        </nav>


        <div class="card bg-light mb-3" v-if="page.id">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-9">
                        <span class="h2 mb-0 d-inline-block me-3">{{page.title}}</span>
                        <span class="badge bg-success" v-if="page.status">active</span>
                        <span class="badge bg-danger" v-else>inactive</span>
                        <span class="badge bg-dark ms-2">{{page.language.name}}</span>
                        <br>
                        <code>/{{page.slug}}</code>
                    </div>
                    <div class="col-md-3 text-end">
                        <div v-if="!pageEditingActive && editingBlock===0">
                            <button type="button" class="btn btn-sm btn-primary text-white"
                                    @click.prevent="pageEditingActive = true">
                                edit page
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row">
            <div v-if="pageEditingActive" class="col-12">


                <div class="card mb-3">
                    <div class="card-body">
                        <form @submit.prevent="savePage">

                            <div class="mb-3">
                                <label class="form-label" for="pageTitle">Title</label>
                                <input type="text" class="form-control" id="pageTitle" v-model="page.title" required>
                            </div>

                            <div class="mb-3">
                                <label class="form-label" for="pageSlug">Slug</label>
                                <input type="text" class="form-control" id="pageSlug" v-model="page.slug" required>
                            </div>

                            <div class="mb-3">
                                <label class="form-label" for="pageStatus">Status</label>
                                <select class="form-control" id="pageStatus" v-model="pageStatus" required>
                                    <option value="0">inactive</option>
                                    <option value="1">active</option>
                                </select>
                            </div>

                            <button type="submit" class="btn btn-primary text-white">Save</button>

                            <button type="button" class="ms-3 btn btn-dark text-white"
                                    @click.prevent="pageEditingActive = false">
                                Close
                            </button>
                            <button type="button" class="ms-3 btn btn-danger text-white" @click="deletePage()">
                                Delete page
                            </button>
                        </form>

                    </div>
                </div>


            </div>

            <div class="col-lg-3">

                <div class="card mb-3" v-if="editingBlock ===0 && !pageEditingActive">
                    <div class="card-header">
                        Sub pages
                    </div>
                    <div class="card-body">
                        <page-list v-if="page.id" :parent="page.id"/>
                    </div>
                </div>

            </div>
            <div v-show="!pageEditingActive" id="editingPart"
                 :class="{'col-lg-9':editingBlock===0,'col-lg-12': editingBlock >0}">


                <div class="mb-2" v-if="editingBlock>0">
                    <div class="card mb-3 bg-light" v-if="editingBlock>0">
                        <div class="card-body" v-if="!block.hasOwnProperty('id')">
                            Loading...
                        </div>
                        <div class="card-body" v-else>
                            <form @submit.prevent="savePageBlock">

                                <component :is="`${block.block}-admin`" :key="block.id"
                                           :inputData="JSON.parse(block.data)"
                                           @dataChange="blockDataChanged"
                                           @dataDelete="blockDataDeleted"
                                           @dataUp="blockDataUp"
                                           @dataDown="blockDataDown"
                                ></component>


                                <div class="my-2 form-floating">
                                    <select class="form-control text-white" id="pageBlockStatus"
                                            v-model="pageBlockStatus"
                                            :class="{'bg-danger': pageBlockStatus<1, 'bg-success': pageBlockStatus>0, }"
                                            required>
                                        <option value="0">inactive</option>
                                        <option value="1">active</option>
                                    </select>
                                    <label for="pageBlockStatus" class="text-white">Status</label>
                                </div>

                                <button type="submit" class="btn btn-primary text-white">Save</button>
                                <button type="button" class="ms-3 btn btn-dark text-white"
                                        @click.prevent="editingBlock = 0">
                                    Close
                                </button>
                                <button type="button" class="ms-3 btn btn-danger text-white"
                                        @click="deletePageBlock()">
                                    Delete block
                                </button>
                            </form>

                        </div>
                    </div>


                    <div class="card mb-4" id="editHolder">
                        <div v-if="!block.hasOwnProperty('id')">
                            Loading...
                        </div>
                        <div class="card-body" :style="{zoom: editingZoom}" v-else>
                            <component :is="block.block" :key="block.id" :data="JSON.parse(block.data)"></component>
                        </div>
                    </div>


                </div>

                <div v-else>

                    <div v-if="blocks.length===0">
                        <div class="card bg-light mb-3">
                            <div class="card-body text-center p-5 text-secondary">
                                No block added on this page.
                            </div>
                        </div>
                    </div>


                    <div  v-for="(block,index) in blocks" :key="block.id">
                        <div class="card position-relative" @mouseenter="hoveredBlock = block.id" @mouseleave="hoveredBlock =0">
                            <div class="card-header bg-white position-absolute" v-show="hoveredBlock === block.id" style="top:0; left:0; right: 0; z-index: 1000000">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        {{block.id}}# <strong>{{block.block}}</strong>

                                        <span v-if="!block.status" class="badge bg-danger ms-2">invisible</span>
                                        <span v-else class="badge bg-success ms-2">visible</span>
                                    </div>
                                    <div class="actions">

                                        <button v-if="index>0" class="btn btn-sm btn-dark ms-2"
                                                @click.prevent="moveBlock(block.id,index-1)">
                                            <font-awesome-icon icon="arrow-up" size="sm"/>
                                        </button>
                                        <button v-if="index<blocks.length-1" class="btn btn-sm btn-dark ms-2"
                                                @click.prevent="moveBlock(block.id,index+1)">
                                            <font-awesome-icon icon="arrow-down" size="sm"/>
                                        </button>

                                        <button class="btn btn-sm btn-primary ms-2"
                                                @click.prevent="editBlock(block.id)">
                                            <i class="fa fa-pencil text-white"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body p-0" :style="{zoom: previewZoom}">
                                <component :is="block.block" :key="block.id" :data="JSON.parse(block.data)"></component>
                            </div>
                        </div>
                    </div>

                    <div class="mb-2 p-2 bg-warning rounded text-dark" @click="openAddNewPopup()">
                        <div class="row">
                            <div class="col-auto d-flex align-items-center">
                                <i class="fa fa-plus-circle fa-2x"/>
                            </div>
                            <div class="col-10 lh-1 d-flex align-items-center">
                                Add new block
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    </div>


    <Modal v-if="addNewPopupIsOpen" size="md" title="Add new block" @modalClose="closeAddNewPopup">

        <div>

            <form @submit.prevent="addNewBlock">

                <div class="mb-3">
                    <label class="form-label" for="newBlockDesign">Block design</label>
                    <select class="form-control" id="newBlockDesign" v-model="newBlockDesign" required>
                        <option value="page-header">Page header</option>
                        <option value="text-block-basic">Full width text block</option>
                        <option value="text-block-left">Half width text with photo (text in left)</option>
                        <option value="text-block-right">Half width text with photo (text in right)</option>
                        <option value="statistics-block">Statistics</option>
                        <option value="info-blocks-first">Info Block 1</option>
                        <option value="info-blocks-second">Info Block 2</option>
                        <option value="info-blocks-third">Info Block 3</option>
                        <option value="info-blocks-fourth">Info Block 4</option>
                        <option value="sponsor-list">Sponsor logo list</option>
                        <option value="sponsor-info">Large sponsor list</option>
                        <option value="contact-persons">Person list</option>
                        <option value="sponsorship-packages">Sponsorship packages</option>
                        <option value="sponsorship-form">Sponsorship reqeust</option>
                        <option value="tabs-view">Tab view</option>
                        <option value="home-hero">Homepage header</option>
                        <option value="who-we-are">Who we are</option>
                        <option value="latest-news">Latest news</option>
                        <option value="staff-list">Staff list</option>
                        <option value="management-info">Management</option>
                        <option value="contact-info">Contact info</option>
                        <option value="map-view">Map</option>
                        <option value="reports-view">Reports</option>
                        <option value="contact-form">Contact form</option>
                        <option value="vacancies">Vacancies</option>
                        <option value="law-download">Law downloads</option>
                        <option value="quote-slider">Quotes</option>
                        <option value="news-list">News Page</option>
                        <option value="donate-page">Donation Page</option>
                    </select>
                </div>

                <div class="my-3" v-if="newBlockDesign">
                    <img :src="`/img/block-design/${newBlockDesign}.png`" class="w-100">
                </div>
                <button type="submit" class="btn btn-primary text-white">Create</button>

            </form>

        </div>

    </Modal>

</template>

<script>
    // @ is an alias to /src


    import {
        savePage,
        getPage,
        deletePage,
        getPageBlocks,
        createPageBlock, savePageBlock, getPageBlock, deletePageBlock, movePageBlock
    } from "../../repositories/AdminRepository";
    import Modal from "../../components/admin/Modal";
    import PageList from "../../components/admin/PageList";

    import PageHeader from "../../components/sections/PageHeader";
    import PageHeaderAdmin from "../../components/admin/sections/PageHeaderAdmin";
    import TextBlockBasic from "../../components/sections/TextBlockBasic";
    import TextBlockBasicAdmin from "../../components/admin/sections/TextBlockBasicAdmin";
    import ContactPersons from "../../components/sections/ContactPersons";
    import ContactPersonsAdmin from "../../components/admin/sections/ContactPersonsAdmin";
    import InfoBlocksFirst from "../../components/sections/InfoBlocksFirst";
    import InfoBlocksFirstAdmin from "../../components/admin/sections/InfoBlocksFirstAdmin";
    import InfoBlocksSecond from "../../components/sections/InfoBlocksSecond";
    import InfoBlocksSecondAdmin from "../../components/admin/sections/InfoBlocksSecondAdmin";
    import InfoBlocksThird from "../../components/sections/InfoBlocksThird";
    import InfoBlocksThirdAdmin from "../../components/admin/sections/InfoBlocksThirdAdmin";
    import InfoBlocksFourth from "../../components/sections/InfoBlocksFourth";
    import InfoBlocksFourthAdmin from "../../components/admin/sections/InfoBlocksFourthAdmin";
    import SponsorInfo from "../../components/sections/SponsorInfo";
    import SponsorList from "../../components/sections/SponsorList";
    import SponsorshipPackages from "../../components/sections/SponsorshipPackages";
    import TabsView from "../../components/sections/TabsView";
    import TabsViewAdmin from "../../components/admin/sections/TabsViewAdmin";
    import TextBlockLeft from "../../components/sections/TextBlockLeft";
    import SponsorListAdmin from "../../components/admin/sections/SponsorListAdmin";
    import HomeHeroAdmin from "../../components/admin/sections/HomeHeroAdmin";
    import HomeHero from "../../components/sections/HomeHero";
    import WhoWeAreAdmin from "../../components/admin/sections/WhoWeAreAdmin";
    import WhoWeAre from "../../components/sections/WhoWeAre";
    import StatisticsBlock from "../../components/sections/StatisticsBlock";
    import StatisticsBlockAdmin from "../../components/admin/sections/StatisticsBlockAdmin";
    import LatestNews from "../../components/sections/LatestNews";
    import LatestNewsAdmin from "../../components/admin/sections/LatestNewsAdmin";
    import TextBlockLeftAdmin from "../../components/admin/sections/TextBlockLeftAdmin";
    import TextBlockRight from "../../components/sections/TextBlockRight";
    import TextBlockRightAdmin from "../../components/admin/sections/TextBlockRightAdmin";
    import StaffList from "../../components/sections/StaffList";
    import StaffListAdmin from "../../components/admin/sections/StaffListAdmin";
    import ManagementInfo from "../../components/sections/ManagementInfo";
    import ManagementInfoAdmin from "../../components/admin/sections/ManagementInfoAdmin";
    import SponsorInfoAdmin from "../../components/admin/sections/SponsorInfoAdmin";
    import ContactInfo from "../../components/sections/ContactInfo";
    import ContactInfoAdmin from "../../components/admin/sections/ContactInfoAdmin";
    import MapViewAdmin from "../../components/admin/sections/MapViewAdmin";
    import MapView from "../../components/sections/MapView";
    import ReportsViewAdmin from "../../components/admin/sections/ReportsViewAdmin";
    import ReportsView from "../../components/sections/ReportsView";
    import ContactForm from "../../components/sections/ContactForm";
    import ContactFormAdmin from "../../components/admin/sections/ContactFormAdmin";
    import SponsorshipFormAdmin from "../../components/admin/sections/SponsorshipFormAdmin";
    import SponsorshipForm from "../../components/sections/SponsorshipForm";
    import VacanciesAdmin from "../../components/admin/sections/VacanciesAdmin";
    import Vacancies from "../../components/sections/Vacancies";
    import LawDownloadAdmin from "../../components/admin/sections/LawDownloadAdmin";
    import LawDownload from "../../components/sections/LawDownload";
    import QuoteSliderAdmin from "../../components/admin/sections/QuoteSliderAdmin";
    import QuoteSlider from "../../components/sections/QuoteSlider";
    import NewsList from "../../components/sections/NewsList";
    import DonatePage from "../../components/sections/DonatePage";
    import DonatePageAdmin from "../../components/admin/sections/DonatePageAdmin";
    import SponsorshipPackagesAdmin from "../../components/admin/sections/SponsorshipPackagesAdmin";

    export default {
        name: "AdminPage",
        components: {
            PageList, Modal,
            PageHeader, PageHeaderAdmin,
            TextBlockBasic, TextBlockBasicAdmin,
            ContactPersons, ContactPersonsAdmin,
            InfoBlocksFirst, InfoBlocksFirstAdmin,
            InfoBlocksSecond, InfoBlocksSecondAdmin,
            InfoBlocksThird, InfoBlocksThirdAdmin,
            InfoBlocksFourth, InfoBlocksFourthAdmin,
            SponsorInfo, SponsorInfoAdmin,
            SponsorList, SponsorListAdmin,
            SponsorshipPackages,SponsorshipPackagesAdmin,
            TabsView, TabsViewAdmin,
            TextBlockLeft, TextBlockLeftAdmin,
            TextBlockRight, TextBlockRightAdmin,
            HomeHero, HomeHeroAdmin,
            WhoWeAre, WhoWeAreAdmin,
            StatisticsBlock, StatisticsBlockAdmin,
            LatestNews, LatestNewsAdmin,
            StaffList, StaffListAdmin,
            ManagementInfo, ManagementInfoAdmin,
            ContactInfo, ContactInfoAdmin,
            MapView, MapViewAdmin,
            ReportsView, ReportsViewAdmin,
            ContactForm, ContactFormAdmin,
            SponsorshipForm, SponsorshipFormAdmin,
            Vacancies, VacanciesAdmin,
            LawDownload, LawDownloadAdmin,
            QuoteSlider, QuoteSliderAdmin,
            NewsList,
            DonatePage, DonatePageAdmin
        },
        data() {
            return {
                page: {},
                blocks: [],
                addNewPopupIsOpen: false,
                newBlockDesign: '',
                pageEditingActive: false,
                editingBlock: 0,
                hoveredBlock: 0,
                block: {}
            }
        },
        computed: {
            currentPageId() {
                return this.$route.params.id;
            },
            pageStatus: {
                get() {
                    return this.page.status ? 1 : 0
                },

                set(value) {
                    this.page.status = value > 0
                }
            },
            pageBlockStatus: {
                get() {
                    return this.block.status ? 1 : 0
                },

                set(value) {
                    this.block.status = value > 0
                }
            },
            previewZoom() {
                return document.getElementById('editingPart').offsetWidth / 1400;
            },
            editingZoom() {
                return document.getElementById('editHolder').offsetWidth / 1400;
            }
        },
        methods: {
            loadPage() {
                getPage(this.currentPageId).then((data) => {
                    if (data.code === 200) {
                        this.page = data.page;
                    }
                })
            },
            loadPageBlocks() {
                getPageBlocks(this.currentPageId).then((blocks) => {
                    this.blocks = blocks;
                })
            },
            openAddNewPopup() {
                this.addNewPopupIsOpen = true;
            },
            closeAddNewPopup() {
                this.addNewPopupIsOpen = false;
            },
            savePage() {
                if (this.page.title !== '' && this.page.slug !== '') {
                    savePage(this.page.id, this.page.title, this.page.slug, this.page.status ? 1 : 0).then((data) => {
                        if (data.code === 200) {
                            this.loadPage();
                        } else {
                            alert(data.message);
                        }

                    })
                }
            },
            deletePage() {
                if (confirm('Are you sure that you want to delete?')) {
                    deletePage(this.page.id).then((data) => {
                        if (data.code === 200) {
                            if (this.page.page) {
                                this.$router.push('/admin/page/' + this.page.page.id);
                            } else {
                                this.$router.push('/admin/dashboard');
                            }
                        } else {
                            alert(data.message + ': ' + data.error);
                        }

                    })
                }
            },
            loadBlock() {
                getPageBlock(this.editingBlock).then((data) => {
                    if (data.code === 200) {
                        this.block = data.block;
                    }
                })
            },
            addNewBlock() {
                if (this.newBlockDesign !== '') {
                    createPageBlock(this.page.id, this.newBlockDesign, this.blocks.length).then((data) => {
                        if (data.code === 200) {
                            this.addNewPopupIsOpen = false;
                            this.editingBlock = data.block;
                            this.loadPageBlocks();
                        } else {
                            alert(data.message);
                        }

                    })
                }
            },

            savePageBlock() {
                if (this.editingBlock > 0) {
                    savePageBlock(this.block.id, this.block.data, this.block.status ? 1 : 0).then((data) => {
                        if (data.code === 200) {
                            alert('Successfully saved');
                            this.loadBlock();
                        } else {
                            alert(data.message);
                        }

                    })
                }
            },


            deletePageBlock() {
                if (confirm('Are you sure that you want to delete?')) {
                    deletePageBlock(this.block.id).then((data) => {
                        if (data.code === 200) {

                            this.editingBlock = 0;
                            this.loadPageBlocks();

                        } else {
                            alert(data.message + ': ' + data.error);
                        }

                    })
                }
            },

            moveBlock(id, position) {
                movePageBlock(id, position).then((data) => {
                    if (data.code === 200) {
                        this.loadPageBlocks();
                    } else {
                        alert(data.message + ': ' + data.error);
                    }

                })
            },


            editBlock(id) {
                this.editingBlock = id;
                this.hoveredBlock = 0;
            },
            blockDataChanged(eventData) {


                let {list, index, key, val} = eventData;

                let json = JSON.parse(this.block.data);
                if (!list) {
                    json[key] = val;
                } else {
                    if (!json[list]) {
                        json[list] = [];
                    }
                    if (!json[list][index]) {
                        json[list][index] = {};
                    }
                    json[list][index][key] = val;
                }
                this.block.data = JSON.stringify(json);
            },
            blockDataDeleted(eventData) {


                let {list, index} = eventData;

                let json = JSON.parse(this.block.data);

                if (!json[list]) {
                    return;
                }
                if (!json[list][index]) {
                    return;
                }

                json[list].splice(index, 1);

                this.block.data = JSON.stringify(json);
            },
            blockDataUp(eventData) {

                let {list, index} = eventData;
                let json = JSON.parse(this.block.data);
                if (!json[list]) {
                    return;
                }
                if (!json[list][index]) {
                    return;
                }
                if (index < 1) {
                    return;
                }
                let newList = [];
                json[list].forEach(function (i, ix) {
                    if (index === ix) {
                        newList[ix - 1] = i;
                    } else if (index - 1 === ix) {
                        newList[ix + 1] = i;
                    } else {
                        newList[ix] = i;
                    }
                });
                json[list] = newList;
                this.block.data = JSON.stringify(json);
            },
            blockDataDown(eventData) {

                let {list, index} = eventData;
                let json = JSON.parse(this.block.data);
                if (!json[list]) {
                    return;
                }
                if (!json[list][index]) {
                    return;
                }
                if (json[list].length - 1 <= index) {
                    return;
                }

                let newList = [];
                json[list].forEach(function (i, ix) {
                    if (index === ix) {
                        newList[ix + 1] = i;
                    } else if (index + 1 === ix) {
                        newList[ix - 1] = i;
                    } else {
                        newList[ix] = i;
                    }
                });
                json[list] = newList;
                this.block.data = JSON.stringify(json);
            },
        },
        mounted() {
            this.loadPage();
            this.loadPageBlocks();
        },
        watch: {
            '$route'() {
                this.loadPage();
                this.loadPageBlocks();
                window.scrollTo(0, 0);
            },
            editingBlock() {
                if (this.editingBlock === 0) {
                    this.block = {};
                    this.loadPageBlocks();
                } else {
                    this.loadBlock();
                }
            }
        }
    }
</script>

<style scoped>


</style>