<template>


    <h3>Sponsors</h3>

    <div class="row">
        <div class="col-lg-4 col-md-6  mb-3"  v-for="(s,index) in inputData.sponsors" :key="index + Math.random()">
            <div class="card">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <button type="button" class="btn btn-sm btn-danger" @click.prevent="deleteItem(index)">delete
                    </button>
                    <button v-if="index>0" type="button" class="btn btn-sm btn-outline-dark"
                            @click.prevent="upItem(index)">
                        <font-awesome-icon icon="arrow-up" size="sm"/>
                    </button>
                    <button v-if="index< inputData.sponsors.length-1" type="button" class="btn btn-sm btn-outline-dark"
                            @click.prevent="downItem(index)">
                        <font-awesome-icon icon="arrow-down" size="sm"/>
                    </button>
                </div>
                <div class="card-body">
                    <div class="mb-3">
                        <label class="form-label">Logo</label>
                        <file-upload :file-path="s.photo" @changed="sponsorLogoChanged($event,index)"/>
                    </div>
                    <div class="mb-3">
                        <label class="form-label" >Text</label>
                        <QuillEditor theme="snow" toolbar="minimal" :content="s.text" contentType="html"
                                     @update:content="this.$emit('dataChange', {list: 'sponsors', index: index, key: 'text', val: $event})" />
                    </div>
                    <div class="form-floating">
                        <input type="text" class="form-control" :value="s.url"
                               @input="$emit('dataChange', {list: 'sponsors', index: index,  key: 'url', val: $event.target.value})">
                        <label>Link</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="my-3">
        <button type="button" class="btn btn-outline-dark" @click.prevent="addNewItem">Add new sponsor</button>
    </div>


</template>

<script>

    import FileUpload from "../FileUpload";

    import { QuillEditor } from '@vueup/vue-quill'
    import '@vueup/vue-quill/dist/vue-quill.snow.css';

    export default {
        name: "SponsorInfoAdmin",
        components: {FileUpload, QuillEditor},
        props: ['inputData'],
        emits: ['dataChange'],
        methods: {
            sponsorLogoChanged(data, index) {
                this.$emit('dataChange', {list: "sponsors", index: index, key: 'photo', val: data})
            },
            addNewItem() {
                let newIndex = 0;
                try {
                    newIndex = this.inputData.sponsors.length;
                } catch (e) {
                    console.log(e);
                }
                this.$emit('dataChange', {list: "sponsors", index: newIndex, key: 'photo', val: ''})
            },
            deleteItem(index) {
                this.$emit('dataDelete', {list: "sponsors", index: index})
            },
            upItem(index) {
                this.$emit('dataUp', {list: "sponsors", index: index})
            },
            downItem(index) {
                this.$emit('dataDown', {list: "sponsors", index: index})
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>