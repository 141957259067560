<template>
    <div class=" bg-primary">
        <div class="container text-end language-menu d-none d-md-block">
            <router-link v-for="l in languages" :key="l.id" class="language-link"
                         :class="{active: language.code ===l.code}" :to="`/${l.code}/${slug}`">
                {{l.code.toUpperCase()}}
            </router-link>
        </div>
        <nav class="navbar navbar-expand-lg navbar-dark">
            <div class="container">
                <button class="navbar-toggler border-0 " type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <router-link class="navbar-brand" :to="`/${language.code}/home`">
                    <img src="../assets/logo-new.png" alt="" class="logo">
                </router-link>

                <div class="d-flex d-md-none lh-1 text-white" style="font-size: 10px">
                    {{settings.header_phone}}
                </div>

                <div class="collapse navbar-collapse ms-md-5" id="navbarSupportedContent">
                    <ul class="navbar-nav mb-2 mb-lg-0">

                        <li class="nav-item position-relative">
                            <a class="nav-link search-button" @click.prevent="showSearch"
                               :class="{active: searchActive}">
                                <i class="fa fa-search"></i>
                            </a>

                            <div v-show="searchActive" class="position-absolute bg-white shadow search-input">
                                <form @submit.prevent="searchSubmitted">
                                    <input class="form-control form-control-sm rounded-pill" type="search"
                                           id="searchInput" v-model="searchKeyword" autocomplete="off"
                                           @blur="closeSearch" @input="search">
                                </form>
                                <div v-if="searchKeyword!=='' && searchResult.length <1" class="small text-center p-3">
                                    Axtarışa uyğun nəticə tapılmadı
                                </div>
                                <div class="p-2" v-if="searchResult.length >0">
                                    <div v-for="r in searchResult" :key="r.id">
                                        <a @click.prevent="goToSearchResult(r.language.code,r.slug)"
                                           class=" text-decoration-none"
                                           :href="`/${r.language.code}/${r.slug}`">
                                            <i class="fa fa-chevron-right small"></i> {{r.title}}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="nav-item" :class="{'dropdown': (m.subs && m.subs.length >0) }" v-for="m in menu"
                            :key="m.text">

                            <a v-if="m.subs && m.subs.length >0" class="nav-link dropdown-toggle"
                               :href="`/${language.code}${m.url}`"
                               :id="`navbarDropdown${m.name}`" role="button"
                               data-bs-toggle="dropdown" aria-expanded="false">
                                {{m.text}}
                            </a>
                            <ul v-if="m.subs && m.subs.length >0" class="dropdown-menu"
                                :aria-labelledby="`navbarDropdown${m.name}`">
                                <li v-for="sm in m.subs" :key="sm.text">
                                    <a class="dropdown-item fw-bold text-secondary"
                                       :href="`/${language.code}${sm.url}`">{{sm.text}}</a>

                                    <router-view v-for="ssm in sm.subs" :key="ssm.text"
                                                 class="dropdown-item text-secondary"
                                                 :to="`/${language.code}/${ssm.url}`"> - {{ssm.text}}
                                    </router-view>

                                </li>
                            </ul>
                            <a v-else class="nav-link" aria-current="page" :href="`/${language.code}${m.url}`">{{m.text}}</a>
                        </li>
                    </ul>

                    <div class="container text-start language-menu d-block d-md-none">
                        <a v-for="l in languages" :key="l.id" class="language-link"
                           :class="{active: language.code ===l.code}" :href="`/${l.code}/${slug}`">
                            {{l.code.toUpperCase()}}
                        </a>
                    </div>

                </div>
            </div>
        </nav>
        <div class="container text-end text-white d-none d-md-block" style="padding-right: 35px; margin-top: -10px;">
            {{settings.header_phone}}
        </div>
    </div>
</template>

<script>
    import {getNavigation, getSearchResult} from "../repositories/GeneralDataRepository";
    import {mapState} from "vuex";

    export default {
        name: "NavBar",
        data() {
            return {
                menu: [],
                searchActive: false,
                searchKeyword: '',
                searchResult: []
            }
        },
        computed: {
            ...mapState(
                {
                    settings: state => state.app.settings,
                    language: state => state.app.frontLanguage,
                    languages: state => state.app.languages
                }
            ),
            slug() {
                if (this.$route.name === 'pageView') {
                    return this.$route.params['slug'] || 'home';
                } else if (this.$route.name === 'news') {
                    return 'news';
                } else {
                    return 'home';
                }
            }
        },
        watch: {
            language() {
                this.loadNavigation();
            }
        },
        methods: {
            showSearch() {
                this.searchActive = true;
                setTimeout(function () {
                    document.getElementById('searchInput').focus();
                }, 500);
            },
            closeSearch() {
                setTimeout(() => {
                    this.searchKeyword = '';
                    this.searchResult = [];
                    this.searchActive = false;
                }, 500)
            },
            goToSearchResult(languageCode, path) {
                this.searchKeyword = '';
                this.searchResult = [];
                this.searchActive = false;
                window.location.href = `/${languageCode}/${path}`;
            },
            search() {
                if (this.searchKeyword !== '') {
                    getSearchResult(this.searchKeyword).then((pages) => {
                        this.searchResult = pages.slice(0, 10);
                    })
                } else {
                    this.searchResult = []
                }
            },
            searchSubmitted(){
                if (this.searchKeyword !== '') {
                    this.$router.push(`/${this.language.code}/search?q=${this.searchKeyword}`)
                    this.closeSearch();
                }
            },
            loadNavigation() {
                getNavigation('top', this.language.code).then(d => {

                    let menu = [];

                    d.filter(m => m.parent === null).forEach(i => {
                        let m = {
                            text: i.title,
                            url: i.link,
                            target: i.target,
                            subs: []
                        };
                        d.filter(m => m.parent !== null && m.parent.id === i.id).forEach(i => {
                            let s = {
                                text: i.title,
                                url: i.link,
                                target: i.target,
                                subs: []
                            };

                            d.filter(m => m.parent !== null && m.parent.id === i.id).forEach(i => {
                                let ss = {
                                    text: i.title,
                                    url: i.link,
                                    target: i.target
                                };
                                s.subs.push(ss)
                            })

                            m.subs.push(s)
                        })
                        menu.push(m)
                    })

                    this.menu = menu;


                })
            }
        },
        created() {

            if (this.language) {
                this.loadNavigation();
            }

        }
    }
</script>

<style lang="scss" scoped>

    .nav-link {
        color: #ffffff !important;
        text-transform: uppercase;
        font-weight: bold;
    }

    .navbar-nav {
        margin-left: auto;

        .nav-item {
            margin: 0 20px;
        }
    }

    .logo {
        height: 50px;
        @media (max-width: 990px) {
            height: 35px;
        }
    }

    .search-button {
        color: #00A0E9 !important;
        background: #ffffff;
        border-radius: 100%;
        height: 30px;
        width: 30px;
        margin: 5px 0;
        text-align: center;
        line-height: 15px;
        @media (min-width: 990px) {
            &.active {
                border-radius: 100% 100% 0 0;
            }
        }
    }

    .search-input {
        left: 0;
        top: 35px;
        padding: 5px;
        width: 300px;
        max-width: 100vw;
        z-index: 1100;
        border-radius: 0 20px 20px 20px;
        @media (max-width: 990px) {
            top: 0 !important;
            border-radius: 20px;
            max-width: 100%;
        }
    }


    .dropdown-toggle {
        &:after {
            content: '';
            width: 12px;
            height: 12px;
            background: url("../assets/img/chevron-down.png") center center no-repeat;
            background-size: contain;
            border: none;
            vertical-align: 0;
        }
    }

    /deep/ .dropdown-menu {
        border-radius: 20px !important;
        overflow: hidden;
        min-width: 200px;
        max-width: 250px;
        word-break: break-word;

        .dropdown-item {
            white-space: unset;
        }

    }

    .language-menu {
        padding-right: 35px;
        margin-bottom: -10px;

        .language-link {
            color: white;
            display: inline-block;
            text-decoration: none;
            padding: 5px 10px;
            font-size: 16px;

            &.active {
                text-decoration: underline;
            }
        }
    }

</style>