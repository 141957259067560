<template>


    <div class="container mt-5">


        <h2>Settings</h2>

        <!-- <div class="my-3">
             <a class="btn btn-success" @click.prevent="addNewPopupIsOpen=true">Create new </a>
         </div> -->
        <div class="my-4">

            <table class="table table-bordered">
                <thead>
                <tr>
                    <th>Keyword</th>
                    <th>Value</th>
                    <th>-</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="settings.length<1">
                    <td colspan="3">
                        <div class="alert alert-danger">
                            no settings
                        </div>
                    </td>
                </tr>
                <tr v-for="c in settings" :key="c.id">
                    <td>{{c.keyword}}</td>
                    <td>{{c.value}}</td>
                    <td>
                        <a class="btn btn-sm btn-primary me-2" @click="editSetting(c.id)">edit</a>
                    </td>
                </tr>
                </tbody>
            </table>

        </div>

    </div>


    <Modal v-if="setting.id" size="md" title="Edit settings" @modalClose="closeEditPopup">

        <div>

            <form @submit.prevent="saveCategory()">

                <div class="mb-3">
                    <label class="form-label"> Keyword</label>
                    <input type="text" class="form-control" v-model="setting.keyword" readonly>
                </div>

                <div class="mb-3">
                    <label class="form-label"> Value</label>
                    <input type="text" class="form-control" v-model="setting.value" required>
                </div>


                <button type="submit" class="btn btn-primary text-white">Save</button>

            </form>

        </div>

    </Modal>

</template>

<script>
    // @ is an alias to /src


    import Modal from "../../components/admin/Modal";

    import {
        getSetting, getSettingList, saveSetting
    } from "../../repositories/AdminRepository";
    import {mapState} from "vuex";

    export default {
        name: "Settings",
        components: {Modal},
        data() {

            return {
                settings: [],
                setting: {}
            }

        },
        computed: {
            ...mapState(
                {currentLanguage: state => state.app.currentLanguage}
            )
        },
        watch: {
            currentLanguage() {
                this.loadSettingList();
            }
        },
        methods: {
            loadSettingList() {

                getSettingList(this.currentLanguage.code).then((d) => {
                    this.settings = d;
                });
            },
            saveCategory() {

                saveSetting(this.setting.id, this.setting.value).then((data) => {
                    if (data.code === 200) {
                        alert('Saved');
                        this.setting = {};
                        this.loadSettingList();
                    } else {
                        alert(data.message + ": " + data.error);
                    }
                });

            },
            async editSetting(id) {
                await getSetting(id).then((data) => {
                        if (data.code === 200) {
                            this.setting = data.setting;
                        }
                    }
                )
            },
            closeEditPopup() {
                this.setting = {};
            },
        },
        mounted() {
            this.loadSettingList();
        }
    }
</script>

<style scoped>


</style>