<template>


    <div class="container mt-5">


        <h2>Categories</h2>

        <div class="my-3">
            <a class="btn btn-success" @click.prevent="addNewPopupIsOpen=true">Create new </a>
        </div>

        <div class="my-4">

            <table class="table table-bordered">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Status</th>
                    <th>-</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="categories.length<1">
                    <td colspan="3">
                        <div class="alert alert-danger">
                            no categories
                        </div>
                    </td>
                </tr>
                <tr v-for="c in categories" :key="c.id">
                    <td>{{c.name}}</td>
                    <td>
                        <span class="badge bg-success" v-if="c.status">active</span>
                        <span class="badge bg-danger" v-else>inactive</span>
                    </td>
                    <td>
                        <a class="btn btn-sm btn-primary me-2" @click="editCategory(c.id)">edit</a>
                        <a class="btn btn-sm btn-danger" @click="deleteCategory(c.id)">delete</a>
                    </td>
                </tr>
                </tbody>
            </table>

        </div>

    </div>


    <Modal v-if="addNewPopupIsOpen" size="sm" title="Add new category" @modalClose="closeAddNewPopup">

        <div>

            <form @submit.prevent="addNewCategory">

                <div class="mb-3">
                    <label class="form-label"> Name</label>
                    <input type="text" class="form-control" v-model="addNewName" required>
                </div>

                <button type="submit" class="btn btn-primary text-white">Create</button>

            </form>

        </div>

    </Modal>

    <Modal v-if="category.id" size="sm" title="Add new category" @modalClose="closeEditPopup">

        <div>

            <form @submit.prevent="saveCategory()">

                <div class="mb-3">
                    <label class="form-label"> Name</label>
                    <input type="text" class="form-control" v-model="category.name" required>
                </div>

                <div class="mb-3">
                    <label class="form-label" for="pageStatus">Status</label>
                    <select class="form-control" id="pageStatus" v-model="categoryStatus" required>
                        <option value="0">inactive</option>
                        <option value="1">active</option>
                    </select>
                </div>


                <button type="submit" class="btn btn-primary text-white">Save</button>

            </form>

        </div>

    </Modal>

</template>

<script>
    // @ is an alias to /src


    import Modal from "../../components/admin/Modal";

    import {
        createCategory,
        deleteCategory,
        getCategory,
        getCategoryList,
        saveCategory
    } from "../../repositories/AdminRepository";

    export default {
        name: "Categories",
        components: {Modal},
        data() {

            return {
                categories: [],
                category: {},
                addNewPopupIsOpen: false,
                addNewName: ''
            }

        },
        computed: {

            categoryStatus: {
                get() {
                    return this.category.status ? 1 : 0
                },

                set(value) {
                    this.category.status = value > 0
                }
            },
        },
        methods: {
            loadCategoryList() {

                getCategoryList().then((d) => {
                    this.categories = d;
                });
            },
            addNewCategory() {

                createCategory(this.addNewName).then((data) => {
                    if (data.code === 200) {
                        alert('Created');
                        this.addNewPopupIsOpen = false;
                        this.addNewName = '';
                        this.loadCategoryList();
                    } else {
                        alert(data.message + ": " + data.error);
                    }
                });

            },
            saveCategory() {

                saveCategory(this.category.id, this.category.name, this.category.status ? 1 : 0).then((data) => {
                    if (data.code === 200) {
                        alert('Saved');
                        this.category = {};
                        this.loadCategoryList();
                    } else {
                        alert(data.message + ": " + data.error);
                    }
                });

            },
            async editCategory(id) {
                await getCategory(id).then((data) => {
                        if (data.code === 200) {
                            this.category = data.category;
                        }
                    }
                )
            },
            deleteCategory(id) {
                if (confirm("Delete?")) {
                    deleteCategory(id).then(() => {

                        this.loadCategoryList()

                    })
                }
            },
            closeAddNewPopup() {
                this.addNewPopupIsOpen = false;
            },
            closeEditPopup() {
                this.category = {};
            },
        },
        mounted() {
            this.loadCategoryList();
        }
    }
</script>

<style scoped>


</style>