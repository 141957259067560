<template>


    <div class="mb-2 form-floating">
        <input type="text" class="form-control" id="inputTitle" :value="inputData.title"
               @input="$emit('dataChange', {key: 'title', val: $event.target.value})">
        <label for="inputTitle">Title</label>
    </div>

    <div class="mb-2 form-floating">
        <input type="text" class="form-control" id="inputSubTitle" :value="inputData.subtitle"
               @input="$emit('dataChange', {key: 'subtitle', val: $event.target.value})">
        <label for="inputSubTitle">SubTitle</label>
    </div>

    <div class="mb-3">
        <label class="form-label">Photo</label>
        <file-upload :file-path="inputData.background"
                     @changed="photoChanged"/>
    </div>

    <h3>Staff</h3>

    <div class="row">
        <div class="col-lg-4 col-md-6  mb-3" v-for="(s,index) in inputData.persons" :key="index">
            <div class="card">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <button type="button" class="btn btn-sm btn-danger" @click.prevent="deleteItem(index)">delete
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-dark">
                        <font-awesome-icon icon="arrow-up" size="sm"/>
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-dark">
                        <font-awesome-icon icon="arrow-down" size="sm"/>
                    </button>
                </div>
                <div class="card-body">
                    <div class="form-floating mb-2">
                        <input type="text" class="form-control" :value="s.position"
                               @input="$emit('dataChange', {list: 'persons', index: index,  key: 'position', val: $event.target.value})">
                        <label>Position</label>
                    </div>
                    <div class="form-floating mb-2">
                        <input type="text" class="form-control" :value="s.name"
                               @input="$emit('dataChange', {list: 'persons', index: index,  key: 'name', val: $event.target.value})">
                        <label>Name</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="my-3">
        <button type="button" class="btn btn-outline-dark" @click.prevent="addNewItem">Add new person</button>
    </div>


</template>

<script>

    import FileUpload from "../FileUpload";

    export default {
        name: "ManagementInfoAdmin",
        components: {FileUpload},
        props: ['inputData'],
        emits: ['dataChange', 'dataDelete'],
        methods: {
            photoChanged(data) {
                this.$emit('dataChange', {key: 'photo', val: data})
            },
            addNewItem() {
                let newIndex = 0;
                try {
                    newIndex = this.inputData.persons.length;
                } catch (e) {
                    console.log(e);
                }
                this.$emit('dataChange', {list: "persons", index: newIndex, key: 'photo', val: ''})
            },
            deleteItem(index) {
                this.$emit('dataDelete', {list: "persons", index: index})
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>