<template>


    <div class="mb-3 form-floating">
        <input type="text" class="form-control" id="inputTitle" :value="inputData.title"
        @input="$emit('dataChange', {key: 'title', val: $event.target.value})">
        <label for="inputTitle">Title</label>
    </div>

    <div class="mb-3">
        <label class="form-label">Background</label>
        <file-upload :file-path="inputData.background"
                     @changed="backgroundChanged"/>
    </div>


</template>

<script>


    import FileUpload from "../FileUpload";

    export default {
        name: "PageHeaderAdmin",
        components: {FileUpload},
        props: ['inputData'],
        emits: ['dataChange'],
        methods: {
            backgroundChanged(data){
                this.$emit('dataChange', {key: 'background', val: data})
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>