<template>

    <div class="modal fade show" tabindex="-1" style="display: block;">
        <div class="modal-dialog" :class="`modal-${size}`">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{title}}</h5>
                    <button type="button" @click="$emit('modalClose')" class="btn-close"
                            aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>


    <teleport to="body">
        <div class="modal-backdrop fade show"></div>
    </teleport>

</template>

<script>
    export default {
        name: "Modal",
        props: {
            "title": {
                type: String,
                default: () => {
                    return '';
                }
            },
            "size": {
                type: String,
                default: () => {
                    return 'lg';
                }
            }
        },
        emits: ['modalClose'],
        mounted() {
            this.$store.commit('app/increaseOpenModalCount');
        },
        unmounted() {
            this.$store.commit('app/decreaseOpenModalCount');
        }
    }
</script>

<style scoped>

    .modal-active {
        display: block;
    }

</style>