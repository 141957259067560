<template>

    <section class="contact-persons">

        <div class="container">
            <h3 class="title" v-html="data.title"></h3>

            <div class="row justify-content-center">

                <div class="col-md-3 person " :class="{'offset-md-1': i>0}" v-for="(p,i) in data.persons" :key="i">

                    <img class="avatar" :src="`${mediaBase}${p.photo}`" :alt="p.name">
                    <p class="name">{{p.name}}</p>
                    <p class="position">{{p.position}}</p>
                    <p class="email">
                        <a :href="`mailto:${p.email}`">{{p.email}}</a>
                    </p>
                    <p class="phone">
                        <a :href="`tel:${p.phone}`">{{p.phone}}</a> <br>
                        <a :href="`tel:${p.mobile}`">{{p.mobile}}</a>
                    </p>

                </div>

            </div>

        </div>

    </section>

</template>

<script>
    import {mediaBase} from "../../repositories/Repository";

    export default {
        name: "ContactPersons",
        props: ['data'],
        data() {
            return {
                 mediaBase: mediaBase
            }

        }
    }
</script>

<style lang="scss" scoped>
    .contact-persons {
        padding: 50px 0;

        .title {
            font-size: 40px;
            margin-bottom: 50px;
            color: #00A0E9;
            text-align: center;

            @media (max-width: 576px) {
                font-size: 24px;
            }
        }

        .person {
            text-align: center;
            line-height: 1.2;

            @media (max-width: 576px) {
                padding: 0 40px;
            }

            .avatar {
                margin-bottom: 30px;
                width: 100%;
            }

            .name {
                color: #DE7A2F;
                font-weight: bold;
                font-size: 30px;
                line-height: 40px;
                padding-bottom: 0;
            }

            .position {
                font-size: 20px;
                color: #929292;
                padding-bottom: 20px;
            }

            .email{
                font-size: 20px;
                color: #00A0E9;
                padding-bottom: 0px;
                a{
                    color: #00A0E9;
                    text-decoration: none;
                }
            }
            .phone{
                font-size: 20px;
                color: #929292;
                padding-bottom: 20px;
                a{
                    color: #929292;
                    text-decoration: none;
                }
            }
        }

    }
</style>