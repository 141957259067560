<template>

    <section class="reports">

        <div class="container">

            <div class="row">
                <div class="col-md-6 order-1 order-md-1">
                    <div class="title" v-if="data.titleFirstColumn" v-html="data.titleFirstColumn"></div>
                </div>
                <div class="col-md-6 order-3 order-md-2">
                    <div class="title" v-if="data.titleSecondColumn" v-html="data.titleSecondColumn"></div>
                </div>
                <div class="col-md-6 order-2  order-md-3 d-flex justify-content-center align-items-center">

                    <div>
                        <div class="mb-3  text-center" v-for="(b,i) in data.firstColumn" :key="i">
                            <a target="_blank" :href="`${mediaBase}${b.file}`" class="button small orange px-5">
                                {{b.title}}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 order-4  order-md-4 d-flex justify-content-center align-items-center report-container">

                    <div>
                        <div class="mb-3  text-center" v-for="(b,i) in data.secondColumn" :key="i">
                            <a target="_blank" :href="`${mediaBase}${b.file}`" class="button small orange px-5">
                                {{b.title}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    </section>

</template>

<script>
    import {mediaBase} from "../../repositories/Repository";

    export default {
        name: "ContactInfo",
        props: ['data'],
        data() {
            return {
                mediaBase: mediaBase
            }
        }
    }
</script>

<style lang="scss" scoped>
    .reports {
        padding: 50px 0;

        .title {
            font-size: 40px;
            line-height: 1.25px;
            color: #00A0E9;
            text-align: center;
            margin-bottom: 50px;

            /deep/ span {
                color: #DE7A2F !important;
            }


            @media (max-width: 576px) {
                font-size: 24px;
            }
        }

        .button{
            position: relative;
            &:after{
                content: '';
                display: inline-block;
                position: absolute;
                right: 10px;
                top: 10px;
                bottom: 10px;
                width: 30px;
                background: url("../../assets/img/pdf.png") no-repeat right;
                background-size: contain;
            }
        }


        .report-container{
            border-left: 1px solid #828282;
        }


    }
</style>