<template>
    <nav-bar/>
    <div>
        <PageHeader :data="header"></PageHeader>

        <div class="container">

            <div class="my-5 w-50">
                <form @submit.prevent="search">
                    <div class="input-group">

                        <input type="search" required minlength="3" class="form-control form-control-lg" v-model="searchKeyword" placeholder="Açar söz...">
                        <div class="input-group-text">
                            <button class="button orange px-4">Axtar</button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="my-5">

                <div class="alert alert-info" v-if="results === null">
                    Axtarış üçün açar sözünü daxil edin
                </div>
                <div class="alert alert-warning" v-else-if="results.length <1">
                    Axtarışınıza uyğun nəticə tapılmadı
                </div>
                <div v-else>

                    <div class="my-3">
                        <strong>{{results.length}}</strong> nəticə tapıldı
                    </div>


                    <div v-for="r in results" :key="r.id">
                        <router-link class="lead   text-decoration-none" :to="`/${r.slug}`">
                            <i class="fa fa-chevron-right small"></i> {{r.title}}
                        </router-link>
                    </div>

                </div>


            </div>

        </div>
    </div>

    <the-footer/>
</template>

<script>
    import {mediaBase} from '../repositories/Repository';
    import {getSearchResult} from "../repositories/GeneralDataRepository";

    import PageHeader from "../components/sections/PageHeader";
    import NavBar from "../components/NavBar";
    import TheFooter from "../components/TheFooter";
    import {mapState} from "vuex";

    export default {
        name: "SearchResult",
        components: {
            TheFooter, NavBar,
            PageHeader
        },
        data() {
            return {
                mediaBase: mediaBase,
                results: null,
                searchKeyword: ''
            }
        },
        watch:{
          "$route"(newVal){
              if(newVal.name === 'searchResult') {
                  this.searchKeyword = this.$route.query['q'];
                  this.search();
              }
          }
        },
        computed: {
            ...mapState(
                {
                    language: state => state.app.frontLanguage,
                }
            ),
            header() {
                return {
                    title: "Axtarış",
                    background: '/img/hero.png',
                    type: 'local'
                }
            }
        },
        methods: {
            search() {
                this.$router.push(`/${this.language.code}/search?q=${this.searchKeyword}`)

                getSearchResult(this.searchKeyword).then((pages) => {
                    this.results = pages;
                })
            }
        },
        mounted() {
            this.searchKeyword = this.$route.query['q'];
            this.search();
        }

    }
</script>

<style lang="scss" scoped>


</style>