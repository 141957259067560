<template>


    <div class="mb-2 form-floating">
        <input type="text" class="form-control" id="inputTitle" :value="inputData.title"
               @input="$emit('dataChange', {key: 'title', val: $event.target.value})">
        <label for="inputTitle">Title</label>
    </div>

    <div class="mb-2 form-floating">
        <input type="text" class="form-control" id="inputSubTitle" :value="inputData.subtitle"
               @input="$emit('dataChange', {key: 'subtitle', val: $event.target.value})">
        <label for="inputSubTitle">SubTitle</label>
    </div>


</template>

<script>


    export default {
        name: "SponsorshipFormAdmin",
        components: {},
        props: ['inputData'],
        emits: ['dataChange', 'dataDelete'],
        methods: {
        }
    }
</script>

<style lang="scss" scoped>
</style>