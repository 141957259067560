<template>

    <div class="row">
        <div class="col-md-6">
            <div class="card">
                <div class="card-header">
                    <h3>First column</h3>
                </div>
                <div class="card-body">
                    <div class="mb-2 form-floating">
                        <input type="text" class="form-control" id="inputTitle" :value="inputData.titleFirstColumn"
                               @input="$emit('dataChange', {key: 'titleFirstColumn', val: $event.target.value})">
                        <label for="inputTitle">Title</label>
                    </div>


                    <h3>Reports</h3>

                    <div class="row">
                        <div class="col-md-6  mb-3" v-for="(s,index) in inputData.firstColumn" :key="index">
                            <div class="card">
                                <div class="card-header d-flex justify-content-between align-items-center">
                                    <button type="button" class="btn btn-sm btn-danger" @click.prevent="deleteItem('firstColumn',index)">delete
                                    </button>
                                    <button type="button" class="btn btn-sm btn-outline-dark">
                                        <font-awesome-icon icon="arrow-up" size="sm"/>
                                    </button>
                                    <button type="button" class="btn btn-sm btn-outline-dark">
                                        <font-awesome-icon icon="arrow-down" size="sm"/>
                                    </button>
                                </div>
                                <div class="card-body">
                                    <div class="mb-3">
                                        <label class="form-label">File</label>
                                        <file-upload :file-path="s.file" @changed="blockPhotoChanged($event, 'firstColumn', index)"/>
                                    </div>
                                    <div class="form-floating mb-2">
                                        <input type="text" class="form-control" :value="s.title"
                                               @input="$emit('dataChange', {list: 'firstColumn', index: index,  key: 'title', val: $event.target.value})">
                                        <label>Title</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="my-3">
                        <button type="button" class="btn btn-outline-dark" @click.prevent="addNewItem('firstColumn')">Add new report</button>
                    </div>

                </div>
            </div>
        </div> <div class="col-md-6">
            <div class="card">
                <div class="card-header">
                    <h3>Second column</h3>
                </div>
                <div class="card-body">
                    <div class="mb-2 form-floating">
                        <input type="text" class="form-control"  :value="inputData.titleSecondColumn"
                               @input="$emit('dataChange', {key: 'titleSecondColumn', val: $event.target.value})">
                        <label >Title</label>
                    </div>


                    <h3>Reports</h3>

                    <div class="row">
                        <div class="col-md-6  mb-3" v-for="(s,index) in inputData.secondColumn" :key="index">
                            <div class="card">
                                <div class="card-header d-flex justify-content-between align-items-center">
                                    <button type="button" class="btn btn-sm btn-danger" @click.prevent="deleteItem('secondColumn',index)">delete
                                    </button>
                                    <button type="button" class="btn btn-sm btn-outline-dark">
                                        <font-awesome-icon icon="arrow-up" size="sm"/>
                                    </button>
                                    <button type="button" class="btn btn-sm btn-outline-dark">
                                        <font-awesome-icon icon="arrow-down" size="sm"/>
                                    </button>
                                </div>
                                <div class="card-body">
                                    <div class="mb-3">
                                        <label class="form-label">File</label>
                                        <file-upload :file-path="s.file" @changed="blockPhotoChanged($event, 'secondColumn', index)"/>
                                    </div>
                                    <div class="form-floating mb-2">
                                        <input type="text" class="form-control" :value="s.title"
                                               @input="$emit('dataChange', {list: 'secondColumn', index: index,  key: 'title', val: $event.target.value})">
                                        <label>Title</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="my-3">
                        <button type="button" class="btn btn-outline-dark" @click.prevent="addNewItem('secondColumn')">Add new report</button>
                    </div>

                </div>
            </div>
        </div>
    </div>


</template>

<script>

    import FileUpload from "../FileUpload";

    export default {
        name: "ContactInfoAdmin",
        components: {FileUpload},
        props: ['inputData'],
        emits: ['dataChange','dataDelete'],
        methods: {
            blockPhotoChanged(data, list, index) {
                this.$emit('dataChange', {list: list, index: index, key: 'file', val: data})
            },
            addNewItem(list) {
                let newIndex = 0;
                try {
                    newIndex = this.inputData[list].length;
                } catch (e) {
                    console.log(e);
                }
                this.$emit('dataChange', {list: list, index: newIndex, key: 'file', val: ''})
            },
            deleteItem(list, index) {
                this.$emit('dataDelete', {list: list, index: index})
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>