<template>

    <section class="info-blocks">

        <div class="container">


            <div v-for="(b,i) in data.quotes" :key="i">

                <div class="row" v-if="i === activeQuote">
                    <div class="col-md-3">
                        <img class="img-fluid" :src="`${mediaBase}${b.photo}`" :alt="b.author">
                    </div>
                    <div class="col-md-9 text-end">
                        <div class="quote">{{b.quote}}</div>
                        <div class="author">{{b.author}}</div>
                        <div class="author-title">{{b.authorTitle}}</div>

                        <div class=" mt-3 text-center text-lg-end">
                            <a class="arrow prev" href="#" @click.prevent="prevPage()"><i class="fa fa-chevron-left"></i></a>
                            <a class="arrow next" href="#" @click.prevent="nextPage()"><i class="fa fa-chevron-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>


            <div class="text-center mt-4" v-if="data.buttonText !==''">
                <a :href="data.buttonLink" class="button orange">{{data.buttonText}}</a>
            </div>

        </div>

    </section>

</template>

<script>
    import {mediaBase} from "../../repositories/Repository";

    export default {
        name: "QuoteSlider",
        props: ['data'],
        data() {
            return {
                mediaBase: mediaBase,
                activeQuote: 0
            }
        },
        methods:{
            prevPage(){
                if(this.activeQuote<1){
                    this.activeQuote = this.data.quotes.length-1;
                } else  {
                    this.activeQuote = this.activeQuote - 1;
                }
            },
            nextPage(){
                if(this.activeQuote===this.data.quotes.length-1){
                    this.activeQuote = 0;
                } else  {
                    this.activeQuote = this.activeQuote + 1;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .info-blocks {
        padding: 50px 0;
        color: #929292;
        background: #F0F0F0;

        @media (max-width: 576px) {
            padding: 50px 20px ;
        }

        .quote {
            font-size: 25px;
            text-align: right;
            margin-bottom: 20px;
            font-weight: lighter;


            @media (max-width: 576px) {
                font-size: 20px;
                text-align: center;
            }
        }

        .author {
            font-size: 25px;
            font-weight: bold;
        }

        .arrow {
            display: inline-block;
            border: 1px solid #00A0E9;
            color: #00A0E9;
            padding: 5px;
            width: 35px;
            height: 35px;
            text-align: center;

            &.next{
                margin-left: 40px;
            }
        }

    }
</style>