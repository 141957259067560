<template>
    <div class="hero" :style="{'background-image': `url(${mediaBase}${data.background})`}">
        <div class="container">

            <div class="row">
                <div class="col-md-7 d-flex align-items-center">

                    <div class="hero-content">
                        <h1 class="hero-title" v-html="data.title"></h1>

                        <p>
                            <a class="hero-link" :href="data.link">{{data.linkText}}</a>
                        </p>
                    </div>

                </div>
                <div class="col-xl-3 col-lg-4 col-md-5 col-sm-8 offset-sm-2 offset-md-0">

                    <div class="payment-block">
                        <div class="header" v-html="translations.donation_page_title"></div>
                        <div class="body">
                            <form @submit.prevent="paymentAmountForm" v-if="paymentStep===1">
                                <div class="amount-selection">
                                    <a class="amount-item" :class="{active: paymentAmount === 10}"
                                       @click.prevent="setAmount(10)" href="#">10</a>
                                    <a class="amount-item" :class="{active: paymentAmount === 20}"
                                       @click.prevent="setAmount(20)" href="#">20</a>
                                    <a class="amount-item" :class="{active: paymentAmount === 30}"
                                       @click.prevent="setAmount(30)" href="#">30</a>
                                </div>
                                <div class="my-3">
                                    <div class="input-group">
                                        <input type="number" step="1" min="1" class="form-control amount-input"
                                               v-model="paymentAmount" required>
                                        <div class="input-group-text">
                                            <img src="../../assets/img/azn-white.svg">
                                        </div>
                                    </div>
                                </div>

                                <div class="row" :class="{'text-danger': periodSelectionError }">
                                    <div class="col-6">
                                        <label>
                                            <input type="radio" class="form-check-input" name="paymentSchedule"
                                                   value="monthly" v-model="paymentType"> {{translations.donation_monthly}}
                                        </label>
                                    </div>
                                    <div class="col-6">
                                        <label>
                                            <input type="radio" class="form-check-input" name="paymentSchedule"
                                                   value="oneshot" v-model="paymentType"> {{translations.donation_one_time}}
                                        </label>
                                    </div>

                                    <div class="text-danger text-center" v-if="periodSelectionError">{{translations.pls_select_period}}</div>

                                    <div class="col-md-12 mt-3 mb-1 small text-center">
                                        <label class="form-check-label">
                                            <input type="checkbox" class="form-check-input" required value="1">
                                            <a class="d-inline-block ms-2" target="_blank" href="/shertler-ve-telebler">
                                                {{translations.donation_agree_terms}}
                                            </a>
                                        </label>
                                    </div>
                                </div>

                                <div class="text-center ">
                                    <button class="button orange small">{{translations.donation_button}}</button>
                                </div>
                            </form>

                            <form @submit.prevent="paymentPersonalInfoForm" v-if="paymentStep===2">

                                <div class="mb-3">
                                    <input type="text" step="1" min="1" class="form-control" :placeholder="translations.donation_name"
                                           v-model="paymentName" required autocomplete="name">
                                </div>

                                <div class="mb-3">
                                    <input type="text" step="1" min="1" class="form-control" :placeholder="translations.donation_fin"
                                           v-model="paymentFin" required>
                                </div>

                                <div class="mb-3">
                                    <input type="tel" step="1" min="1" class="form-control"
                                           :placeholder="translations.donation_phone"
                                           v-model="paymentPhone" required autocomplete="tel">
                                </div>
                                <div class="mb-3">
                                    <input type="email" step="1" min="1" class="form-control" :placeholder="translations.donation_email"
                                           v-model="paymentEmail" required autocomplete="email">
                                </div>


                                <div class="text-center " v-if="paymentLoading">
                                    {{translations.donation_pls_wait}}
                                </div>
                                <div class="text-center " v-else>
                                    <button class="button orange small">{{translations.donation_button}}</button>
                                </div>
                            </form>

                            <div v-if="paymentStep===3">

                                <div class="text-thanks text-center lead">
                                    "SOS Uşaq Evlərinə ianə etdiyiniz üçün Sizə təşəkürümüzü bildiririk!
                                    Hər bir ianə etdiyiniz manat, UŞAQLARIN xoşbəxt həyatına doğru kiçik bir addımdır!"
                                </div>

                            </div>

                            <div class="payment-steps">
                                <div class="step" :class="{active: paymentStep>=1}">
                                    <span class="order">1</span> <br>
                                    <span class="title">
                                        {{translations.donation_step_amount}}
                                    </span>
                                </div>
                                <div class="step" :class="{active: paymentStep>=2}">
                                    <span class="order">2</span> <br>
                                    <span class="title">
                                        {{translations.donation_step_personal_info}}
                                    </span>
                                </div>
                                <div class="step" :class="{active: paymentStep>=3}">
                                    <span class="order">3</span> <br>
                                    <span class="title">
                                        {{translations.donation_step_payment}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {mediaBase} from "../../repositories/Repository";
    import {pay} from "../../repositories/GeneralDataRepository";
    import {mapState} from "vuex";

    export default {
        name: "HomeHero",
        props: ['data'],
        data() {
            return {
                mediaBase: mediaBase,
                paymentAmount: 20,
                paymentType: '',
                paymentStep: 1,
                paymentName: '',
                paymentFin: '',
                paymentPhone: '',
                paymentEmail: '',
                paymentLoading: false,
                periodSelectionError: false
            }
        }, computed: {
            ...mapState({
                translations: state => state.app.translations
            }),
            languageCode() {
                return this.$route.params.language||'az';
            },
        },
        watch:{
            paymentType(newVal){
                if(newVal!==''){
                    this.periodSelectionError = false;
                }
            }
        },
        methods: {
            setAmount(amount) {
                this.paymentAmount = amount;
            },
            paymentAmountForm() {

                if( this.paymentType=== ''){
                    this.periodSelectionError = true;
                } else {
                    this.periodSelectionError = false;
                    this.paymentStep = 2;
                }

            },
            paymentPersonalInfoForm() {
                this.paymentLoading = true;
                pay(this.paymentName, this.paymentFin, this.paymentEmail, this.paymentPhone, this.paymentType, this.paymentAmount, this.languageCode).then(data => {
                    this.requestInProgress = false;
                    if (data.code === 200) {

                        localStorage.setItem("paymentReferenceId", data.id);
                        window.location.href = data.url;

                    } else {
                        alert(data.error)

                        this.paymentLoading = false;
                    }
                })


            }
        }
    }
</script>

<style lang="scss" scoped>
    .hero {
        background: url("../../assets/img/hero.png");
        background-size: cover;
        min-height: 400px;

        .hero-title {
            color: #ffffff;
            font-size: 75px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        .hero-link {
            color: #ffffff;
            font-size: 25px;
            text-decoration: none;
        }
    }

    .payment-block {
        border-radius: 40px;
        border: 5px solid #ffffff;
        background: white;
        overflow: hidden;
        position: relative;
        margin-bottom: -30px;
        margin-top: 50px;
        box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.5);

        .header {
            background: #DE7A2F;
            text-align: center;
            color: white;
            font-size: 20px;
            line-height: 20px;
            font-weight: bold;
            padding: 20px;
        }

        .body {
            padding: 20px;

            .amount-selection {
                text-align: center;

                .amount-item {
                    display: inline-block;
                    background: #BDBDBD;
                    color: white;
                    text-decoration: none;
                    padding: 5px 10px;
                    border-radius: 5px;
                    margin: 0 15px;
                    position: relative;
                    font-size: 20px;
                    line-height: 20px;
                    font-weight: bold;

                    &:after {
                        content: "";
                        position: absolute;
                        right: -12px;
                        top: 0;
                        width: 20px;
                        height: 15px;
                        background: url("../../assets/img/azn-gold.svg") center no-repeat;
                        background-size: contain;
                    }

                    &.active {
                        background: #0d6efd;
                    }
                }
            }

            .amount-input {
                position: relative;

            }

            .input-group-text {
                background: #DCDCDC;
            }

            .payment-steps {
                margin-top: 15px;
                display: flex;
                justify-content: space-between;

                .step {
                    flex: 1;
                    text-align: center;
                    font-size: 13px;
                    color: #828282;

                    .order {
                        display: inline-block;
                        background: #BDBDBD;
                        color: white;
                        text-decoration: none;
                        padding: 5px 10px;
                        border-radius: 5px;
                        position: relative;
                        font-size: 16px;
                        line-height: 16px;
                        font-weight: bold;


                    }

                    .title {
                        margin-top: 5px;
                        display: inline-block;
                        line-height: 13px !important;
                    }

                    &.active {
                        .order {
                            background: #0d6efd;
                        }
                    }

                }
            }


            .text-thanks {
                color: #DE7A2F;
            }

        }
    }
</style>