<template>


    <section class="google-map">

        <GoogleMap
                api-key="AIzaSyD7-un0s7EHnFMh-yNDKC5xMi6pZE2cYEw"
                style="width: 100%; height: 500px"
                :center="center"
                :zoom="parseInt(data.zoom)"
        >
            <Marker v-for="(b,i) in data.blocks" :key="i" :options="markerOptions(b)"/>
        </GoogleMap>

    </section>

</template>

<script>
    import {mediaBase} from "../../repositories/Repository";
    import {GoogleMap, Marker} from 'vue3-google-map';

    export default {
        name: "MapView",
        props: ['data'],
        components: {GoogleMap, Marker},
        data() {
            return {
                mediaBase: mediaBase
            }
        }, computed: {
            center() {

                let parts = this.data.center.split(',');

                return {lat: parseFloat(parts[0]), lng: parseFloat(parts[1])}
            }
        }, methods: {
            markerOptions(block) {
                if(!block.coordinates || block.coordinates.split(',')<2){
                    return {
                        position:  this.center,
                        title: block.title
                    }
                }
                let parts = block.coordinates.split(',');
                return {
                    position: {lat: parseFloat(parts[0]), lng: parseFloat(parts[1])},
                    title: block.title,
                    icon: `${this.mediaBase}${block.photo}`,
                    size: 20,
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .info-blocks {
        padding: 100px 0;

        .title {
            font-size: 40px;
            line-height: 50px;
            color: #00A0E9;
            text-align: center;
            margin-bottom: 50px;

            /deep/ span {
                color: #DE7A2F !important;
            }
        }


        .info-block {
            text-align: center;

            img.icon {
                width: 50%;
                margin-bottom: 20px;
            }

            .title {
                font-size: 25px;
                line-height: 46px;
                margin-bottom: 10px;
                color: #00A0E9;
                font-weight: bold;
            }

            .text {
                font-size: 25px;
                color: #929292;
                white-space: pre;
            }

        }


    }
</style>