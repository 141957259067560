<template>

    <section class="footer bg-primary">

        <div class="container">
            <div class="row">
                <div class="col-lg-3 ">
                    <img src="../assets/logo-new.png" alt="logo" class="img-fluid">

                    <div class="footer-menu-first-column">
                        <div class="mb-3 mt-5">
                            <strong>{{translations.footer_national_office}}</strong><br>
                            {{settings.footer_address}}
                        </div>
                        <div class="mb-3" v-html="settings.footer_phone"></div>
                        <div>{{settings.footer_email}}</div>
                    </div>
                </div>

                <div class="col-lg-9">
                    <div class="row" v-if="screenSize> 768">
                        <div class="col-lg-4" v-for="m in  menu" :key="m.id">

                            <div class="footer-menu">
                                <p class="menu-header">{{m.text}}</p>

                                <p v-if="m.subs && m.subs.length>0">
                                    <router-link class="menu-link" v-for="s in  m.subs" :to="`/${language.code}${s.url}`" :target="s.target"
                                                 :key="s.id">
                                        {{s.text}} <br>
                                    </router-link>
                                </p>
                            </div>

                        </div>
                    </div>

                    <div class="position-relative px-5" v-else>

                        <div class="swiper-button-prev stats-prev"></div>
                        <div class="swiper-button-next stats-next"></div>

                        <swiper :slides-per-view="1" :autoplay="{delay: 5000,}" :space-between="0" :autoHeight="true"
                                :navigation="{ nextEl: '.stats-next', prevEl: '.stats-prev', }">
                            <swiper-slide v-for="m in  menu" :key="m.id">
                                <div class="footer-menu">
                                    <p class="menu-header">{{m.text}}</p>

                                    <p v-if="m.subs && m.subs.length>0">
                                        <router-link class="menu-link" v-for="s in  m.subs" :to="`/${language.code}${s.url}`"
                                                     :target="s.target"
                                                     :key="s.id">
                                            {{s.text}} <br>
                                        </router-link>
                                    </p>
                                </div>
                            </swiper-slide>

                        </swiper>
                    </div>

                </div>


            </div>
            <div class="mt-4">
                <div class="text-center text-md-end">

                    <a :href="settings.social_facebook" class="social-icon" target="_blank">
                        <i class="fa fa-fw fa-facebook-square"></i>
                    </a>
                    <a :href="settings.social_instagram" class="social-icon" target="_blank">
                        <i class="fa fa-fw fa-instagram"></i>
                    </a>
                    <a :href="settings.social_youtube" class="social-icon" target="_blank">
                        <i class="fa fa-fw fa-youtube-play"></i>
                    </a>

                </div>
            </div>
        </div>

    </section>

</template>

<script>
    import {getNavigation} from "../repositories/GeneralDataRepository";
    import {mapState} from "vuex";

    import SwiperCore, {A11y, Navigation} from 'swiper';
    import {Swiper, SwiperSlide} from 'swiper/vue';

    SwiperCore.use([A11y, Navigation]);
    // Import Swiper styles
    import "swiper/swiper-bundle.css";


    export default {
        name: "TheFooter",
        data() {
            return {
                menu: [],
                screenSize: 0
            }
        },
        components: {Swiper, SwiperSlide},
        computed: {
            ...mapState(
                {
                    settings: state => state.app.settings,
                    language: state => state.app.frontLanguage,
                    translations: state => state.app.translations
                }
            )
        },
        watch: {
            language() {
                this.loadNavigation();
            }
        },
        methods: {

            loadNavigation() {
                getNavigation('footer', this.language.code).then(d => {

                    let menu = [];

                    d.filter(m => m.parent === null).forEach(i => {
                        let m = {
                            text: i.title,
                            url: i.link,
                            target: i.target,
                            subs: []
                        };
                        d.filter(m => m.parent !== null && m.parent.id === i.id).forEach(i => {
                            let s = {
                                text: i.title,
                                url: i.link,
                                target: i.target
                            };
                            m.subs.push(s)
                        })
                        menu.push(m)
                    })

                    this.menu = menu;


                })
            }

        },
        created() {
            this.loadNavigation();
        },
        mounted() {
            this.screenSize = window.innerWidth
        }
    }
</script>

<style lang="scss" scoped>

    .footer {
        padding: 50px 0 30px 0;
        color: #ffffff;
        font-size: 18px;

        a {
            color: #ffffff;
        }

        div {
            .footer-menu {
                border-left: 1px solid #ffffff;
                padding-left: 20px;


                .menu-header {
                    font-size: 30px;
                    line-height: 30px;
                }

                .menu-link {
                    text-decoration: none;
                }
            }

            &:first-child {
                > .footer-menu {
                    border-left: none;
                }
            }

        }

        .social-icon {
            display: inline-block;

            min-width: 34px;
            height: 34px;
            line-height: 20px;
            background: white;
            border-radius: 10px;
            padding: 7px;
            margin-right: 15px;
            color: #00A0E9;

        }

        @media (max-width: 990px) {

            .footer-menu-first-column {


                text-align: center;
                padding-bottom: 20px;
                margin: 20px;
                border-bottom: 1px solid #ffffff;

            }

            .footer-menu {
                border: none !important;
                text-align: center;
            }
            .swiper-button-prev, .swiper-button-next {
                font-size: 15px !important;
                color: #ffffff;
                &:after{
                    font-size: 25px !important;
                }
            }

        }
    }

</style>