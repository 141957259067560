<template>

    <div>
        <div v-if="pages.length === 0">
            <div class="alert alert-info">No pages...</div>
        </div>
        <div class="my-2 p-2 border-bottom border-secondary row lh-1" v-for="page in pages" :key="page.id">

            <div class="col-md-6">
                <router-link :to="`/admin/page/${page.id}`">
                    {{page.title}}
                </router-link></div>
            <div class="col-md-6">
                <code>/{{page.slug}}</code></div>
        </div>

        <div class="mb-2 p-2 bg-secondary rounded text-white" @click="openAddNewPopup()">
            <div class="row">
                <div class="col-auto d-flex align-items-center">
                    <font-awesome-icon icon="plus-circle" size="2x"/>
                </div>
                <div class="col-auto lh-1 d-flex align-items-center">
                    Add new page
                </div>
            </div>
        </div>


        <Modal v-if="addNewPopupIsOpen" size="md" title="Add new page" @modalClose="closeAddNewPopup">


            <div>

                <form @submit.prevent="addNewPage">

                    <div class="mb-3">
                        <label class="form-label" for="newPageTitle">Page title</label>
                        <input type="text" class="form-control" id="newPageTitle" v-model="newPageTitle" required>
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="newPageSlug">Slug</label>
                        <input type="text" class="form-control" id="newPageSlug" v-model="newPageSlug" required>
                    </div>

                    <button type="submit" class="btn btn-primary text-white">Create</button>

                </form>

            </div>

        </Modal>

    </div>

</template>

<script>
    // @ is an alias to /src


    import {createPage, getPageList} from "../../repositories/AdminRepository";
    import Modal from "../../components/admin/Modal";
    import {mapState} from "vuex";

    export default {
        name: "PageList",
        components: {Modal},
        props: ["parent"],
        data() {

            return {
                pages: [],
                addNewPopupIsOpen: false,
                newPageTitle: '',
                newPageSlug: ''
            }

        },
        computed:{
            ...mapState(
                {currentLanguage: state => state.app.currentLanguage}
            )
        },

        methods: {
            loadPageList() {
                getPageList(this.parent, this.currentLanguage.code).then((pages) => {
                    this.pages = pages;
                })
            },
            openAddNewPopup() {
                this.addNewPopupIsOpen = true;
            },
            closeAddNewPopup() {
                this.addNewPopupIsOpen = false;
            },
            addNewPage() {
                if (this.newPageTitle !== '' && this.newPageSlug !== '') {
                    createPage(this.newPageTitle, this.newPageSlug, this.currentLanguage.code,  this.parent).then((data) => {
                        if (data.code === 200) {
                            this.newPageTitle= '';
                            this.newPageSlug = '';
                            this.addNewPopupIsOpen = false;
                            this.$router.push('/admin/page/' + data.id);
                        } else {
                            alert(data.message);
                        }

                    })
                }
            }
        },
        mounted() {
            this.loadPageList();
        }, watch: {
            parent() {
                this.loadPageList();
            },
            currentLanguage(){
                this.loadPageList();
            }
        }
    }
</script>

<style scoped>


</style>