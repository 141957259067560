<template>

    <nav class="navbar navbar-dark bg-primary navbar-expand-lg">
        <div class="container">
            <a class="navbar-brand" href="/admin">
                <img src="../../assets/logo.png" alt="" height="40">
            </a>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">

                    <li class="nav-item dropdown bg-white" v-if="currentLanguage.id">
                        <a class="nav-link dropdown-toggle fw-bold text-dark" href="#" id="navbarDropdown" role="button"
                           data-bs-toggle="dropdown" aria-expanded="false">
                            {{currentLanguage.name}}
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li v-for="l in languages" :key="l.id">
                                <a class="dropdown-item" @click="setCurrentLanguage(l)">{{l.name}}</a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <router-link class="nav-link" to="/admin/dashboard">Dashboard</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/admin/settings">Settings</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/admin/contents">Contents</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/admin/translations">Translations</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/admin/categories">Categories</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/admin/news">News</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/admin/navigations">Navigations</router-link>
                    </li>
                    <li class="nav-item" v-if="adminUser.role==='admin'">
                        <router-link class="nav-link" to="/admin/payments">Donations</router-link>
                    </li>
                    <li class="nav-item" v-if="adminUser.role==='admin'">
                        <router-link class="nav-link" to="/admin/subscribers">Subscribers</router-link>
                    </li>
                    <li class="nav-item" v-if="adminUser.role==='admin'">
                        <router-link class="nav-link" to="/admin/users">Users</router-link>
                    </li>
                    <li class="nav-item"  >
                        <a class="nav-link"  href="#" @click.prevent="logoutUser">Logout</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <router-view></router-view>

</template>

<script>

    import {getLanguageList} from "../../repositories/AdminRepository";
    import {mapState} from "vuex";

    export default {
        name: 'Admin',
        components: {},
        data() {

            return {
                languages: [],
            }

        },
        computed: {
            ...mapState(
                {
                    currentLanguage: state => state.app.currentLanguage,
                    adminUser: state => state.app.adminUser,
                }
            )
        },
        methods: {
            setCurrentLanguage(language) {
                localStorage.setItem('languageCode', language.code);
                this.$store.dispatch('app/setCurrentLanguage', language);
            },
            logoutUser(){
                if(confirm('Are you sure you want to logout?')) {
                    localStorage.removeItem("token");
                    location.reload();
                }
            }
        },
        created() {

            let lang = localStorage.getItem('languageCode');
            if (!lang) {
                lang = 'az';
                localStorage.setItem('languageCode', 'az');
            }
            getLanguageList().then(d => {
                this.languages = d;
                d.forEach(l => {
                    if (l.code === lang) {
                        this.$store.dispatch('app/setCurrentLanguage', l);
                    }
                })
            })
        }
    }
</script>

<style lang="scss" scoped>

</style>
