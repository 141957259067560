<template>
    <nav-bar/>
    <div>
        <PageHeader :data="header"></PageHeader>

        <div class="container py-5" v-if="result === 'loading'">
            <div class="alert alert-info">
                Yüklənir...
            </div>
        </div>
        <div class="container py-5" v-else-if="result === 'not_found'">
            <div class="alert alert-danger">
                Axtardığınız ödəniş məlumatı tapılmadı
            </div>
        </div>

        <div class="container py-5" v-else>


            <h3>İstifadəçi məlumatları</h3>
            <strong>Adınız: </strong> {{payment.name}} <br>
            <strong>FİN: </strong> {{payment.fin}} <br>
            <strong>E-mail: </strong> {{payment.email}} <br>
            <strong>Telefon: </strong> {{payment.phone}} <br><br>

            <div v-if="payment.type === 'subscription'">
                <h3>Abunəlik detalları</h3>

                <strong>Məbləğ: </strong> {{payment.amount}} AZN<br>
                <strong>Toplam ianə: </strong> {{payment.totalPaid}} AZN<br>
                <strong>Status: </strong>
                <span class="badge bg-success" v-if="payment.subStatus ===1  ">aktiv</span>
                <span class="badge bg-warning text-dark" v-else-if="payment.subStatus === 2">dayandırılmış</span>
                <span class="badge bg-danger" v-else>uğursuz</span>


                <br>
                <br>
                <div v-if="payment.subStatus ===1 ">
                    <button class="btn btn-sm btn-warning text-dark mb-3" @click.prevent="unsubscribe()">
                        Abunəliyimi ləğv et
                    </button>
                </div>


            </div>
            <div v-else>

                <h3>Ödəniş detalları</h3>

                <strong>Məbləğ: </strong> {{payment.amount}} AZN<br>
                <strong>Status: </strong>
                <span class="badge bg-success" v-if="payment.status ===1 || payment.status ===3 ">uğurlu</span>
                <span class="badge bg-warning text-dark" v-else-if="payment.status === 4">geri qaytarılmış</span>
                <span class="badge bg-danger" v-else>uğursuz</span>

                <br>
                <br>

                <!--<button class="btn btn-sm btn-danger" v-if="payment.status ===1 || payment.status ===3"
                        @click.prevent="refund()">
                    Ödənişi ləğv et
                </button>-->

            </div>

        </div>


    </div>

    <the-footer/>
</template>

<script>
    import {mediaBase} from '../repositories/Repository';
    import {getPaymentDetails, unsubscribeUser} from "../repositories/GeneralDataRepository";

    import PageHeader from "../components/sections/PageHeader";
    import NavBar from "../components/NavBar";
    import TheFooter from "../components/TheFooter";

    export default {
        name: "PaymentManagement",
        components: {
            TheFooter, NavBar,
            PageHeader
        },
        data() {
            return {
                mediaBase: mediaBase,
                result: 'loading',
                payment: null
            }
        },
        computed: {
            header() {
                return {
                    title: "Ödəniş",
                    background: '/img/hero.png',
                    type: 'local'
                }
            }
        },
        methods: {


            getPaymentDetails() {
                getPaymentDetails(this.$route.params['hash']).then((res) => {

                        if (res.code === 200) {
                            this.result = 'done';
                            this.payment = res.payment;
                        } else {
                            this.result = 'not_found';
                        }

                    }
                )
            },
            refund() {
                if (confirm('Ödənişi ləğv etmək istədiyinzdən əminsiniz?')) {
                    //todo request
                }
            },
            unsubscribe() {
                if (confirm('Abunəliyinizi ləğv etmək istədiyinzdən əminsiniz?')) {

                  unsubscribeUser(this.$route.params['hash']).then((res) => {

                        if (res.code === 200) {
                          this.getPaymentDetails();
                          alert('Əməliyyat uğurla icra olundu!')
                        } else {
                          alert('Xəta baş verdi! Zəhmət olmasa adminstasiya ilə əlaqə saxlayın')
                        }

                      }
                  )
                }
            }
        },
        mounted() {

            this.getPaymentDetails();

        }

    }
</script>

<style lang="scss" scoped>


</style>