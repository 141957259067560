<template>
    <section class="news">

        <div class="container">

            <h2 class="title">{{data.title}}</h2>

            <div class="row mb-5">

                <div class="col-md-4 col-sm-12 mb-4" v-for="s in news" :key="s.id">

                    <div class="news-item">
                        <router-link :to="`/post/${s.slug}`">
                            <a class="news-image" :style="{'background-image':  `url('${mediaBase}${s.photo}')`}"
                               :alt="s.title"> </a>
                        </router-link>
                        <div class="news-text">
                            <router-link :to="`/post/${s.slug}`" class="news-title">
                                {{s.title}}
                            </router-link>
                            <p v-html="newsText(s.data).substring(0,120)" class="mb-1 mb-md-4">
                            </p>
                            <div class="text-start text-md-center">
                                <router-link :to="`/post/${s.slug}`">ətraflı ></router-link>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div>
                <div class=" input-container mx-auto">
                    <div v-if="requestInProgress">
                        <div class="text-center text-warning lead">
                            Sorğunuz göndərilir...
                        </div>
                    </div>
                    <div v-else-if="subscribtionSuccess">
                        <div class="text-center alert alert-success fw-bold lead">
                            Uğurla abunə oldunuz!
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="screenSize>768">
                            <form @submit.prevent="subscribeToNewsletter()" class="newsletter-form">
                                <input type="email" required class="newsletter-input" placeholder="e-mail"
                                       v-model="subscriberEmail">
                                <button type="submit" class="button orange">
                                    {{data.subscribeText}}
                                </button>
                            </form>
                        </div>
                        <div v-else>
                            <form @submit.prevent="subscribeToNewsletter()" class="text-center">
                                <input type="email" required class="form-control form-control-lg mb-3" placeholder="e-mail"
                                       v-model="subscriberEmail">
                                <button type="submit" class="button orange">
                                    {{data.subscribeText}}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </section>
</template>

<script>
    import {mediaBase} from "../../repositories/Repository";
    import {getNewsList, subscribe} from "../../repositories/GeneralDataRepository";

    export default {
        name: "LatestNews",
        props: ["data"],
        data() {
            return {
                mediaBase: mediaBase,
                news: [],
                requestInProgress: false,
                subscribtionSuccess: false,
                subscriberEmail: '',
                screenSize: 0
            }
        },
        methods: {
            subscribeToNewsletter() {
                this.requestInProgress = true;
                subscribe(this.subscriberEmail).then(data => {
                    this.requestInProgress = false;
                    if (data.code === 200) {
                        this.subscribtionSuccess = true;
                        this.subscriberEmail = '';
                    } else {
                        alert(data.error)
                    }
                })
            },
            newsText(originalString) {
                return originalString.replace(/(<([^>]+)>)/gi, " ")
            }
        },
        mounted() {
            getNewsList(1, 3).then(d => {
                if (d.code === 200) {
                    this.news = d.news;
                }
            })

            this.screenSize = window.innerWidth
        }
    }
</script>

<style lang="scss" scoped>

    .news {
        padding: 50px 0;
        background: #F0F0F0;

        .title {
            text-align: center;
            font-size: 40px;
            color: #00A0E9;
            margin-bottom: 50px;
            font-weight: bold;

            @media (max-width: 576px) {
                font-size: 24px;
            }
        }

        .news-item {
            padding: 10px;
            font-size: 23px;

            @media (max-width: 576px) {
                font-size: 18px;
            }

            .news-image {
                display: block;
                width: 100%;
                border-radius: 40px;
                padding-bottom: 75%;
                overflow: hidden;

                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                border: 5px solid #ffffff;
                box-shadow: 0 0 6px #828282;
            }

            .news-text {

                padding: 20px 30px;
                text-align: justify;

                @media (max-width: 576px) {
                    padding: 15px;
                }

                .news-title {
                    margin-bottom: 20px;
                    color: #4F4F4F;
                    font-weight: bold;
                    text-decoration: none;
                    display: block;
                    height: 70px;
                    overflow: hidden;

                    @media (max-width: 576px) {
                        height: 60px;
                        margin-bottom: 10px;
                    }

                }

                p {
                    color: #929292;
                    text-decoration: none;
                    display: block;
                    font-size: 20px;
                    margin-bottom: 20px;
                    height: 90px;
                    overflow: hidden;
                    line-height: 1.5;
                    @media (max-width: 576px) {
                        font-size: 18px;
                        line-height: 1.25;
                        height: 68px;
                    }

                }

                a {
                    color: #00A0E9;
                    text-decoration: none;
                }
            }


        }
    }

    .input-container {

        width: 30%;

        @media (max-width: 576px) {
            width: 90%;
        }

        .newsletter-form {
            position: relative;
            width: 100%;
            height: 50px;
            margin-bottom: 40px;


            .newsletter-input {
                border: 1px solid #E0E0E0;
                border-radius: 10px;
                padding: 15px;
                font-size: 20px;
                position: absolute;
                right: 50px;
                left: 0;
                width: calc(100% - 50px);
                color: #828282;

                &:focus {
                    border: 1px solid #E0E0E0;
                    outline: none;
                }

                &:focus-visible {
                    border: 1px solid #E0E0E0;
                    outline: none;
                }

                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #E0E0E0;
                    opacity: 1; /* Firefox */
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: #E0E0E0;
                }

                &::-ms-input-placeholder { /* Microsoft Edge */
                    color: #E0E0E0;
                }
            }

            .button {
                position: absolute;
                right: 0;
                font-weight: normal;
                padding: 15px 25px;
                font-size: 21px;
            }
        }
    }
</style>
