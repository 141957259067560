<template>
    <div class="who-we-are">

        <div class="container">

            <div class="row">

                <div class="col-md-6 text-center text-md-start">
                    <h2 class="who-we-are-title">{{data.title}}</h2>

                    <p class="lead">
                        {{data.subtitle}}
                    </p>
                    <p>

                        {{data.text}}
                    </p>

                    <p class="py-5">
                        <router-link to="/donate" class="button orange">
                            {{data.linkText}}
                        </router-link>
                    </p>
                </div>

                <div class="col-md-6">


                    <div class="row">

                        <div class="col-md-5 " :class="{'offset-md-2': index===2}"
                             v-for="(block,index) in data.blocks" :key="index">
                            <div class="square-block">
                                <router-link :to="`${block.url||'#'}`"><img :src="`${mediaBase}${block.photo}`"></router-link>
                                <p class="title">{{block.title}}</p>
                                <p class="link" >
                                    <router-link :to="`${block.url||'#'}`">ətraflı ></router-link>
                                </p>
                            </div>
                        </div>

                    </div>


                </div>

            </div>

        </div>

    </div>

</template>

<script>
    import {mediaBase} from "../../repositories/Repository";

    export default {
        name: "WhoWeAre",
        props: ['data'],
        data() {
            return {
                mediaBase: mediaBase
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../../node_modules/bootstrap/scss/bootstrap";

    .who-we-are {
        background: url("../../assets/img/logo-bg-tile.png");
        padding: 50px 0;
        color: #828282;

        .who-we-are-title {
            font-size: 24px;
            color: #00A0E9;
            font-weight: bold;
        }

        .lead {
            font-size: 12px;
            font-weight: bold;
        }

        p {
            font-size: 12px;
            font-weight: normal;
        }
    }


    @media (min-width: 576px) {
        .who-we-are {
            .who-we-are-title {
                font-size: 55px;
            }
            .lead {
                font-size: 24px;
            }

            p {
                font-size: 18px;
            }
        }

    }

    .square-block {
        display: block;
        position: relative;
        margin-bottom: 20px;
        border-radius: 50px;
        overflow: hidden;

        img {
            width: 100%;
        }

        p.title {
            position: absolute;
            left: 15px;
            right: 15px;
            bottom: 0;
            text-align: center;
            color: #ffffff;
            font-size: 22px;
            line-height: 25px;


        }

        p.link {
            display: none;
            position: absolute;
            left: 20px;
            right: 0;
            bottom: 0;
            text-align: left;
            font-size: 20px;

            a {
                color: #ffffff;
                text-decoration: none;
            }
        }

        &:hover {
            p.title {
                bottom: calc(50% - 20px) !important;
            }
            p.link{
                display: block;
            }
        }

    }


</style>