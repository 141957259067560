import Repository, {baseUrl} from './Repository';


export async function loginUser(login, password) {
    const endPoint = `admin/login?email=${login}&password=${password}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function getMe(token) {
    const endPoint = `admin/me?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function getPageList(parentId, languageCode) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/pages?token=${token}&parent=${parentId}&language=${languageCode}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                if (response.data.code === 200) {
                    return response.data.pages;
                } else {
                    return [];
                }
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function getPageListFull() {
    let token = localStorage.getItem("token");
    const endPoint = `admin/page/list?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data.pages;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function createPage(title, slug, language, parent) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/page?token=${token}&title=${title}&slug=${slug}&language=${language}&parent=${parent}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function savePage(id, title, slug, status) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/page/${id}?token=${token}&title=${title}&slug=${slug}&status=${status}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function deletePage(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/page/${id}/delete?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function getPage(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/page/${id}?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function getPageBlocks(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/page/${id}/blocks?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data.blocks;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function createPageBlock(id, design, order) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/page/${id}/block?token=${token}&block=${design}&order=${order}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function savePageBlock(id, data, status) {
    let token = localStorage.getItem("token");
    const formData = new FormData()
    formData.append('data', data);
    formData.append('status', status);

    const endPoint = `admin/pageBlock/${id}?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`, formData)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function getPageBlock(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/pageBlock/${id}?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function deletePageBlock(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/pageBlock/${id}/delete?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}

export async function movePageBlock(id, position) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/pageBlock/${id}/move/${position}?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function upload(file, onUploadProgress) {
    let formData = new FormData();

    formData.append("photo", file);

    return await Repository.post(`${baseUrl}/admin/upload`, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        },
        onUploadProgress
    }).then(response => {
        return response;
    }).catch(error => {
        console.log(JSON.stringify(error));
        return {code: 2, message: JSON.stringify(error)};
    });
}


export async function getLanguageList() {
    let token = localStorage.getItem("token");
    const endPoint = `admin/languages?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data.languages;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function getCategoryList() {
    let token = localStorage.getItem("token");
    const endPoint = `admin/categories?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data.categories;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function getSettingList(languageCode) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/settings?language=${languageCode}&token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data.settings;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function getSetting(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/setting/${id}?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function saveSetting(id, value) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/setting/${id}?token=${token}&value=${value}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function getContentList(languageCode) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/contents?language=${languageCode}&token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data.contents ;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function getContent(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/content/${id}?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function saveContent(id, title, description, file) {
    let token = localStorage.getItem("token");
    const formData = new FormData()
    formData.append('token', token);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('file', file);

    const endPoint = `admin/content/${id}`;
    return await Repository.post(`${baseUrl}/${endPoint}`, formData)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function getTranslationList(languageCode) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/translations?language=${languageCode}&token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data.translations;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function getTranslation(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/translation/${id}?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function saveTranslation(id, value) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/translation/${id}?token=${token}&value=${value}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function getUserList() {
    let token = localStorage.getItem("token");
    const endPoint = `admin/users?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data.users;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function createUser(title, email, role, password) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/user?token=${token}&name=${title}&email=${email}&role=${role}&password=${password}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function saveUser(id, title,email, role, password, status) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/user/${id}?token=${token}&name=${title}&status=${status}&email=${email}&role=${role}&password=${password}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function deleteUser(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/user/${id}/delete?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function getUser(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/user/${id}?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function createCategory(title) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/category?token=${token}&name=${title}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function saveCategory(id, title, status) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/category/${id}?token=${token}&name=${title}&status=${status}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function deleteCategory(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/category/${id}/delete?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function getCategory(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/category/${id}?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function getNewsList() {
    let token = localStorage.getItem("token");
    const endPoint = `admin/newsList?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data.news;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function createNews(title, slug, category) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/news?token=${token}&title=${title}&slug=${slug}&category=${category}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}

export async function saveNews(id, title, date, category, slug, photo, data, status) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/news/${id}?token=${token}`;

    const formData = new FormData()
    formData.append('title', title);
    formData.append('category', category);
    formData.append('slug', slug);
    formData.append('photo', photo);
    formData.append('status', status);
    formData.append('date', date);
    formData.append('data', data);

    return await Repository.post(`${baseUrl}/${endPoint}`, formData)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function deleteNews(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/news/${id}/delete?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function getNews(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/news/${id}?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function getSubscriberList() {
    let token = localStorage.getItem("token");
    const endPoint = `admin/subscribers?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data.subscribers;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function getSubscriberExport() {
    let token = localStorage.getItem("token");
    const endPoint = `admin/subscribers/export?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}

export async function getPaymentList(keyword, page) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/payments?token=${token}&page=${page}&keyword=${keyword}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function getPaymentExport() {
    let token = localStorage.getItem("token");
    const endPoint = `admin/payments/export?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}

export async function deletePayment(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/payment/${id}/delete?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}

export async function refundPayment(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/payment/${id}/refund?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}

export async function cashInPayment(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/payment/${id}/cashIn?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function getSubscriptionsList(keyword, page) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/subscriptions?token=${token}&page=${page}&keyword=${keyword}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function getSubscribtionExport() {
    let token = localStorage.getItem("token");
    const endPoint = `admin/subscriptions/export?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function deleteSubscription(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/subscription/${id}/delete?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}

export async function unSubSubscription(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/subscription/${id}/unsubscribe?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function getNavigationList() {
    let token = localStorage.getItem("token");
    const endPoint = `admin/navigations?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data.navigations;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function getNavigation(languageCode, id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/navigation/${id}?language=${languageCode}&token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function createNavigationItem(title, listOrder, navigation, parent) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/navigation/item?token=${token}&title=${title}&order=${listOrder}&navigation=${navigation}&parent=${parent === null ? '' : parent}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function saveNavigationItem(id, title, listOrder, link, target) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/navigation/item/${id}?token=${token}&title=${title}&order=${listOrder}&target=${target}&link=${encodeURI(link)}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function deleteNavigationItem(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/navigation/item/${id}/delete?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 2, message: JSON.stringify(error)};
        });
}


export async function getNavigationItem(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/navigation/item/${id}?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}
